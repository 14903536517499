import React from 'react';
import styled from 'styled-components';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components/macro';

export type CheckboxOnChangeEvent = (
  name: string,
  value: any,
  checked: boolean,
) => void;

interface StyledCheckboxProps {
  checked?: boolean;
}

interface CheckboxProps extends StyledCheckboxProps {
  label?: string;
  onChange: CheckboxOnChangeEvent;
  name: string;
  value?: any;
  disabled?: boolean;
}

export const CheckboxContainer = styled.label<{
  checked?: boolean;
  disabled?: boolean;
}>`
  display: block;
  position: relative;
  padding: 0 1rem;
  border-radius: 1rem;
  line-height: 2rem;
  background: var(--grey-700);
  cursor: pointer;
  border: 1px solid var(--grey-700);

  &:hover {
    transition: background-color 0.2s, border-color 0.2s;
    border-color: var(--gold-30);
  }

  ${ifProp(
    'checked',
    css`
      background-color: var(--gold-30);

      &:hover {
        background-color: var(--gold-50);
      }
    `,
  )}

  ${ifProp(
    'disabled',
    css`
      pointer-events: none;
      background: var(--grey-750);
      border-color: var(--grey-750);
      color: var(--grey-400);
    `,
  )}
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const PillCheckbox = ({
  checked,
  label,
  onChange,
  value,
  name,
  disabled,
  ...props
}: CheckboxProps) => (
  <CheckboxContainer checked={checked} disabled={disabled}>
    <HiddenCheckbox
      checked={checked}
      name={name}
      disabled={disabled}
      {...props}
      onChange={event => {
        onChange(name, value, event.target.checked);
      }}
      value={value}
    />
    {label}
  </CheckboxContainer>
);

PillCheckbox.defaultProps = {
  checked: false,
  label: '',
};
