import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import { Link } from 'react-router-dom';

import { ButtonIcon } from '../ButtonIcon';

const listItemStyle = css`
  padding: 0.5rem 0.75rem;
  border: 1px solid var(--grey-750);
  border-radius: 0.25rem;
  background: var(--grey-750);
  display: block;

  ${ButtonIcon} {
    margin: -1px 0;
  }

  ${ifProp(
    'onClick',
    css`
      cursor: pointer;
      border-color: var(--grey-600);

      &:hover {
        transition: border-color 0.2s;
        border-color: var(--gold-30);
      }
    `,
    '',
  )}
`;

export const ListItem = styled.div`
  ${listItemStyle}
`;

export const ListItemLink = styled(Link)`
  ${listItemStyle};
  cursor: pointer;

  &:hover {
    transition: border-color 0.2s;
    border-color: var(--gold-30);
  }
`;
