import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '../../../../components/Grid';
import { Heading } from '../../../../components/Heading';
import { User } from '../../../../../types/User';
import { CircleImage } from '../../../../components/CircleImage';
import { getFileLink } from '../../../../../utils/get-file-link';
import { AuthContext } from '../../../../../context/AuthContext';

interface Props {
  user?: User | null;
}

export function WelcomeTitle(props: Props) {
  const { user } = props;
  const { t } = useTranslation();
  const { accessToken } = React.useContext(AuthContext);

  const imageSrc = getFileLink(user?.coach_profile?.photo, accessToken);

  return (
    <Row alignItems="center">
      <Col>
        <CircleImage imageSrc={imageSrc} size={64} />
      </Col>
      <Col>
        <Heading weight={400}>{t('title.welcome')}</Heading>
        <Heading>{user?.first_name || ''}</Heading>
      </Col>
    </Row>
  );
}
