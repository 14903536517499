import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';
import { pickBy } from '../../../../utils/pick-by';
import { FilterType } from '../../../../types/Filter';
import { mapValues } from '../../../../utils/map-values';

const selectSlice = (state: RootState) => state.watchlist || initialState;

export const selectPlayers = createSelector(
  [selectSlice],
  state => state.players,
);

export const selectLoading = createSelector(
  [selectSlice],
  state => state.loading,
);

export const selectFilter = createSelector(
  [selectSlice],
  state => state.filter,
);

export const selectNormalizedFilter = createSelector([selectFilter], filter => {
  return pickBy(filter, (value, key) => {
    switch (key) {
      case FilterType.SAT_DEGREE:
      case FilterType.TOEFL_SCORE:
      case FilterType.DUOLINGO_SCORE:
      case FilterType.GRADE_POINT_AVG: {
        return (
          initialState.filter[key][0] !== value[0] ||
          initialState.filter[key][1] !== value[1]
        );
      }
    }

    return Array.isArray(value)
      ? value?.length || Object.keys(value).length
      : value?.value || value?.value === false;
  });
});

export const selectFilterRequest = createSelector(
  [selectNormalizedFilter],
  filter => {
    return mapValues(filter, (value, key) => {
      switch (key) {
        case FilterType.GENDER:
        case FilterType.START_DATE:
        case FilterType.DIVISION:
        case FilterType.POSITION:
        case FilterType.ADMISSION: {
          return value.map(option => option.value);
        }
      }

      return value?.value || value?.value === false ? value.value : value;
    });
  },
);

export const selectSortBy = createSelector(
  [selectSlice],
  state => state.sortBy,
);

export const selectSortDirection = createSelector(
  [selectSlice],
  state => state.sortDirection,
);

export const selectTotal = createSelector([selectSlice], state => state.total);

export const selectPlayerIds = createSelector(
  [selectSlice],
  state => state.playerIds,
);
