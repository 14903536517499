import styled, { css } from 'styled-components/macro';
import { ifProp, switchProp } from 'styled-tools';

interface Props {
  level?: 1 | 2;
  color?: 'red' | 'grey' | '';
  background?: 'red' | 'grey' | '';
}

export const Span = styled.span<Props>`
  color: ${switchProp('color', {
    red: 'var(--red)',
    grey: 'var(--grey-300)',
  })};

  ${ifProp(
    { level: 2 },
    css`
      font-size: 14px;
      line-height: 18px;
    `,
  )}

  ${switchProp('background', {
    red: css`
      background: var(--red);
      color: var(--white);
    `,
    grey: css`
      background: var(--grey-300);
      color: var(--white);
    `,
  })};

  ${ifProp(
    'background',
    css`
      padding: 1px 0.5rem;
      border-radius: 4px;
    `,
  )}
`;

Span.defaultProps = {
  level: 1,
  color: '',
  background: '',
};
