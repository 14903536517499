import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { programFormActions as actions } from './index';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import {
  DeleteAction,
  GetAction,
  InitialValues,
  OverviewBody,
  OverviewInitialValues,
  SaveAction,
} from './types';
import { FileResponse } from '../../../../../../types/Dict';
import { uploadFile } from '../../../../../../services/files';
import { api } from '../../../../../../services/api';
import { Program } from '../../../../../../types/Program';
import { toOption } from '../../../../../../utils/to-options';
import { selectProgramForm } from './selectors';

const getToInitialValues = (data: Program): InitialValues => ({
  overview: {
    name: data.name,
    sport: data.sport ? toOption(data.sport, ['id', 'name']) : undefined,
    website: data.website,
    division: data.division
      ? toOption(data.division, ['id', 'name'])
      : undefined,
    university: data.university
      ? toOption(data.university, ['id', 'name'])
      : undefined,
    conference: data.conference
      ? toOption(data.conference, ['id', 'name'])
      : undefined,
    awards: data.awards,
    logo_id: data.logo_id,
    logo: [data.logo],
  },
  media: {
    photos: data.media
      .filter(({ type }) => type === 'photo')
      .map(({ media }) => media),
    videos: data.media
      .filter(({ type }) => type === 'video')
      .map(({ media }) => media),
  },
});

const overviewToBody = (overview: OverviewInitialValues): OverviewBody => ({
  name: overview.name,
  sport_id: overview.sport?.value || null,
  website: overview.website,
  division_id: overview.division?.value || null,
  university_id: overview.university?.value || null,
  conference_id: overview.conference?.value || null,
  awards: overview.awards,
  logo_id: overview.logo_id,
});

function* saveProgram(action: PayloadAction<SaveAction>) {
  const { programId, values, resolve, reject } = action.payload;
  const { initialValues } = yield select(selectProgramForm);

  try {
    const { overview, media } = values;

    // @ts-ignore
    const photosToCreate: File[] = media.photos
      .filter(media => media?.file)
      .map(({ file }) => file);
    const photosResponse: FileResponse[] = yield all(
      photosToCreate.map(file => uploadFile(file)),
    );

    // @ts-ignore
    const videosToCreate: File[] = media.videos
      .filter(media => media?.file)
      .map(({ file }) => file);
    const videosResponse: FileResponse[] = yield all(
      videosToCreate.map(file => uploadFile(file)),
    );

    // @ts-ignore
    if (!overview.logo[0]) {
      overview.logo_id = null;
    }
    // @ts-ignore
    else if (overview.logo[0]?.file) {
      const file: FileResponse | null = yield call(
        uploadFile,
        // @ts-ignore
        overview.logo[0].file,
      );

      overview.logo_id = file?.id || null;
    }

    const overviewResponse: AxiosResponse<Program> = yield call(
      programId ? api.patch : api.post,
      programId ? `program/${programId}` : 'program',
      overviewToBody(overview),
    );

    const photosToDelete = initialValues.media.photos.filter(
      oldPhoto => !media.photos.find(photo => oldPhoto.id === photo?.id),
    );

    const videosToDelete = initialValues.media.videos.filter(
      oldVideo => !media.videos.find(video => oldVideo.id === video?.id),
    );

    yield all([
      ...photosResponse.map(media =>
        call(api.post, `program/${overviewResponse.data.id}/media`, {
          type: 'photo',
          media_id: media.id,
        }),
      ),
      ...videosResponse.map(media =>
        call(api.post, `program/${overviewResponse.data.id}/media`, {
          type: 'video',
          media_id: media.id,
        }),
      ),
      ...photosToDelete.map(media =>
        call(
          api.delete,
          `program/${overviewResponse.data.id}/media/${media.id}`,
        ),
      ),
      ...videosToDelete.map(media =>
        call(
          api.delete,
          `program/${overviewResponse.data.id}/media/${media.id}`,
        ),
      ),
    ]);

    yield put(actions.saveSuccess());
    resolve(overviewResponse.data);
  } catch (error) {
    yield put(actions.saveError());
    reject();
  }
}

function* getProgram(action: PayloadAction<GetAction>) {
  try {
    const { programId } = action.payload;
    const response: AxiosResponse<Program> = yield call(
      api.get,
      `program/${programId}`,
    );

    yield put(actions.getSuccess(getToInitialValues(response.data)));
  } catch (error) {
    yield put(actions.getError());
  }
}

function* deleteProgram(action: PayloadAction<DeleteAction>) {
  const { programId, resolve, reject } = action.payload;

  try {
    yield call(api.delete, `program/${programId}`);

    yield put(actions.deleteSuccess());
    resolve({});
  } catch (error) {
    yield put(actions.deleteError());
    reject();
  }
}

export function* programFormSaga() {
  yield takeLatest(actions.saveProgram.type, saveProgram);
  yield takeLatest(actions.getProgram.type, getProgram);
  yield takeLatest(actions.deleteProgram.type, deleteProgram);
}
