import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { UploadsManager } from '../../services/uploads-manager';

interface Props {
  children: ReactNode;
}

type Uploads = string[];

interface ContextData {
  uploads: Uploads;
}

export const UploadsContext = createContext({} as ContextData);

export function UploadsProvider(props: Props) {
  const { children } = props;
  const [uploads, setUploads] = useState<Uploads>([]);

  const handleChange = (uploads: Uploads) => {
    setUploads(uploads);
  };

  useEffect(() => {
    UploadsManager.addChangeListener(handleChange);

    return function () {
      UploadsManager.removeChangeListener(handleChange);
    };
  }, []);

  return (
    <UploadsContext.Provider value={{ uploads }}>
      {children}
    </UploadsContext.Provider>
  );
}
