import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { programFormSaga } from './saga';
import {
  DeleteAction,
  GetAction,
  InitialValues,
  ProgramFormState,
  SaveAction,
} from './types';

export const initialState: ProgramFormState = {
  initialValues: {
    overview: {
      name: '',
      sport: undefined,
      website: '',
      division: undefined,
      university: undefined,
      conference: undefined,
      awards: '',
      logo_id: null,
      logo: [],
    },
    media: {
      photos: [],
      videos: [],
    },
  },
  saveLoading: false,
  getLoading: false,
  deleteLoading: false,
};

const slice = createSlice({
  name: 'programForm',
  initialState,
  reducers: {
    saveProgram(state, action: PayloadAction<SaveAction>) {
      state.saveLoading = true;
    },
    saveSuccess(state) {
      state.saveLoading = false;
    },
    saveError(state) {
      state.saveLoading = false;
    },

    getProgram(state, action: PayloadAction<GetAction>) {
      state.getLoading = true;
    },
    getSuccess(state, action: PayloadAction<InitialValues>) {
      state.initialValues = action.payload;
      state.getLoading = false;
    },
    getError(state) {
      state.getLoading = false;
    },

    resetProgram(state) {
      state.initialValues = initialState.initialValues;
    },

    deleteProgram(state, action: PayloadAction<DeleteAction>) {
      state.deleteLoading = true;
    },
    deleteSuccess(state) {
      state.deleteLoading = false;
    },
    deleteError(state) {
      state.deleteLoading = false;
    },
  },
});

export const { actions: programFormActions } = slice;

export const useProgramFormSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: programFormSaga });
  return { actions: slice.actions };
};
