import { Option } from '../app/components/Select/types';

export enum FilterType {
  AVAILABILITY = 'availability',
  GENDER = 'gender_id',
  START_DATE = 'semester_id',
  DIVISION = 'division_id',
  POSITION = 'position_id',
  FOOT = 'foot_id',
  ADMISSION = 'admission_id',
  SAT_DEGREE = 'sat_degree',
  TOEFL_SCORE = 'toefl_score',
  DUOLINGO_SCORE = 'duolingo_score',
  GRADE_POINT_AVG = 'grade_point_avg',
  BUDGET = 'budget',
  COACH_TYPE = 'coach_type_id',
  CONFERENCE = 'conference_id',
  PROGRAM = 'program_id',
  SPORT = 'sport_id',
}

export type Filters = {
  [FilterType.AVAILABILITY]?: Option | null;
  [FilterType.GENDER]?: Option[];
  [FilterType.START_DATE]?: Option[];
  [FilterType.DIVISION]?: Option[];
  [FilterType.POSITION]?: Option[];
  [FilterType.FOOT]?: Option | null;
  [FilterType.ADMISSION]?: Option[];
  [FilterType.SAT_DEGREE]?: [number, number];
  [FilterType.TOEFL_SCORE]?: [number, number];
  [FilterType.DUOLINGO_SCORE]?: [number, number];
  [FilterType.GRADE_POINT_AVG]?: [number, number];
  [FilterType.BUDGET]?: [number, number];
  [FilterType.COACH_TYPE]?: Option[];
  [FilterType.CONFERENCE]?: Option[];
  [FilterType.PROGRAM]?: Option[];
  [FilterType.SPORT]?: Option[];
};

export type SortBy = {
  label: string;
  value: string | number;
};
