import styled, { keyframes } from 'styled-components';

const spinKeyframe = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Loader = styled.div`
  //position: relative;

  &:after {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: solid 4px rgba(255, 255, 255, 0.2);
    border-top-color: #fff;
    animation: ${spinKeyframe} 1s infinite linear, ${fadeIn} 0.2s ease-out;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;
  }
`;
