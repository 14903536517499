import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { css } from 'styled-components';

import { CircleImage } from '../CircleImage';

const ItemStyle = css`
  width: 4rem;
  height: 4rem;
  display: block;
  position: relative;
  border: none;
  cursor: pointer;

  &:hover {
    color: var(--gold-30);
  }

  &.active {
    color: var(--white) !important;

    &:before {
      content: '';
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      bottom: 0.5rem;
      left: 0.5rem;
      border-radius: 50%;
      background: var(--gold-30);
      display: block;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  ${CircleImage} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*width: 44px;
    height: 44px;
    border: 6px solid var(--black);*/
  }
`;

export const ItemLink = styled(NavLink)`
  ${ItemStyle}
`;

export const ItemButton = styled.button`
  ${ItemStyle}
`;
