import ModalOrigin, { BaseModalBackground } from 'styled-react-modal';
import styled, { keyframes } from 'styled-components/macro';
import { prop } from 'styled-tools';

import { cardStyle } from '../Card';

interface Props {
  isOpen?: boolean;
  children: any;
  width?: number;
  offset?: number;
  overflow?: 'hidden' | 'auto';
  padding?: number;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalBackground = styled(BaseModalBackground)`
  animation: ${fadeIn} 0.2s ease-out;
  z-index: 2;
  overflow: auto;
`;

export const Modal = ModalOrigin.styled<Props>`
  ${cardStyle};
  width: ${props => props.width || 426}px;
  overflow: ${prop('overflow', 'auto')};
  background: var(--grey-800);
  z-index: 3;
  max-height: calc(100vh - 1rem);
`;
