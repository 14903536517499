import * as React from 'react';

import { Filters, FilterType } from '../../../types/Filter';
import { Col, Row } from '../Grid';
import { FilterPill } from './FilterPill';

interface Props {
  filter: Filters;
  onClearFilter: (string) => void;
  onResetFilter: () => void;
}

export function PlayerSelectedFilters(props: Props) {
  const { filter, onClearFilter, onResetFilter } = props;

  if (!Object.keys(filter).length) return null;

  return (
    <Row gutter={0.75} gutterY={0.75}>
      {Object.entries(filter).map(([k, value]) => {
        const key = k as FilterType;

        return (
          <Col gutter={0.75} gutterY={0.75} key={key}>
            <FilterPill name={[key]} value={value} onClick={onClearFilter} />
          </Col>
        );
      })}
    </Row>
  );
}
