import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getIn, useFormikContext } from 'formik';
import prettyBytes from 'pretty-bytes';

import ImageUploading from '../../../../../components/ImageUploading';
import { Heading } from '../../../../../components/Heading';
import { Col, Row } from '../../../../../components/Grid';
import { ListItem } from '../../../../../components/ListItem';
import { Ellipsis } from '../../../../../components/Ellipsis';
import { ButtonIcon } from '../../../../../components/ButtonIcon';
import { Icon } from '../../../../../components/Icon';
import { Button } from '../../../../../components/Button';
import { Label } from '../../../../../components/Label';
import { P } from '../../../../../components/P';
import { UploadsContext } from '../../../../../../context/UploadsContext';
import { getUploadId } from '../../../../../../utils/get-upload-id';

export function Media() {
  const { t } = useTranslation();
  const { values, setFieldTouched, touched, errors, setFieldValue } =
    useFormikContext<{ [name: string]: any }>();
  const { uploads } = useContext(UploadsContext);

  const fields = [
    {
      name: 'overview.logo',
      label: t('label.athleticProgramLogo'),
      max: 1,
    },
    {
      name: 'media.photos',
      label: t('label.photos'),
    },
    {
      name: 'media.videos',
      label: t('label.videos'),
      acceptType: ['mpeg4', 'mp4'],
    },
  ];

  const onAddFile = (name, files) => {
    setFieldValue(name, [...(getIn(values, name) || []), ...files]);
  };

  const onDeleteFile = (name, index) => {
    setFieldValue(
      name,
      getIn(values, name).filter((_, i) => i !== index),
    );
  };

  let filesLength = 0;
  for (let i = 0, l = fields.length; i < l; i++) {
    filesLength = filesLength + (getIn(values, fields[i].name)?.length || 0);
  }

  return (
    <Row gutter={2}>
      <Col xs={12} gutter={2}>
        <Heading level={2}>{t('title.media')}</Heading>
      </Col>
      <Col xs={6} gutterY={0.5} gutter={2}>
        <P>{t('infoMessage.forms.athleticProgramMedia')}</P>
      </Col>
      {filesLength ? (
        <Col xs={12} gutterY={2} gutter={2}>
          <Row gutterY={2}>
            {fields.map(({ name, label, max }) => {
              const files = getIn(values, name) || [];

              if (!files.length) return null;

              return (
                <Col xs={12} gutterY={2} key={name}>
                  <Row>
                    <Col xs={12}>
                      <Label>{label}</Label>
                    </Col>
                    {files.map((file, index) => (
                      <Col xs={12} key={index}>
                        <Row>
                          <Col gutterY={0.5} xs={6}>
                            <ListItem>
                              <Row>
                                <Col xs={6}>
                                  <Ellipsis>
                                    {getIn(file, 'file.name') ||
                                      getIn(file, 'original_name')}
                                  </Ellipsis>
                                </Col>
                                <Col xs="auto">
                                  {prettyBytes(
                                    +getIn(file, 'file.size') ||
                                      +getIn(file, 'size') ||
                                      0,
                                  )}
                                </Col>
                                <Col>
                                  <ButtonIcon
                                    block
                                    onClick={() => {
                                      onDeleteFile(name, index);
                                    }}
                                    type="button"
                                    $loading={
                                      getIn(file, 'file') &&
                                      uploads.indexOf(
                                        getUploadId(getIn(file, 'file')),
                                      ) !== -1
                                    }
                                  >
                                    <Icon name="cross" />
                                  </ButtonIcon>
                                </Col>
                              </Row>
                            </ListItem>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </Col>
              );
            })}
          </Row>
        </Col>
      ) : null}
      <Col xs={12} gutterY={2} gutter={2}>
        <Row>
          {fields.map(({ name, label, max, acceptType }) => {
            if (max && (getIn(values, name) || [])?.length >= max) return null;

            return (
              <Col key={name}>
                <ImageUploading
                  value={[]}
                  onChange={files => {
                    onAddFile(name, files);
                  }}
                  allowNonImageType={!!acceptType}
                  multiple={!max || max > 1}
                  maxNumber={max}
                  acceptType={acceptType}
                >
                  {({ onImageUpload, dragProps }) => (
                    <Button
                      onClick={() => {
                        onImageUpload();
                        setFieldTouched(name);
                      }}
                      border
                      invalid={!!(getIn(touched, name) && getIn(errors, name))}
                      {...dragProps}
                    >
                      {t(`button.upload__name`, { name: label })}
                    </Button>
                  )}
                </ImageUploading>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
}
