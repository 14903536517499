import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from '../../routes/PrivateRoute';

import { AgencyHomePage } from './AgencyHomePage/Loadable';
import { AgencyPlayersPage } from './AgencyPlayersPage/Loadable';
import { AgencyInvitePlayerPage } from './AgencyInvitePlayerPage/Loadable';
import { AgencyCreatePlayerPage } from './AgencyCreatePlayerPage/Loadable';
import { AgencyInviteCoachPage } from './AgencyInviteCoachPage/Loadable';
import { AgencySignUpPage } from './AgencySignUpPage/Loadable';
import { Navigation } from '../../components/Navigation';

export function AgencyPage() {
  return (
    <>
      <Navigation />
      <Switch>
        <PrivateRoute
          path="/agency/players"
          component={AgencyPlayersPage}
          tags={['agency_member', 'agency_owner', 'super_admin']}
        />
        <PrivateRoute
          path="/agency/invite-coach"
          component={AgencyInviteCoachPage}
          tags={['agency_member', 'agency_owner', 'super_admin']}
        />
        <PrivateRoute
          path="/agency/invite-player"
          component={AgencyInvitePlayerPage}
          tags={['agency_member', 'agency_owner', 'super_admin']}
        />
        <PrivateRoute
          path="/agency/create-player"
          component={AgencyCreatePlayerPage}
          tags={['agency_member', 'agency_owner', 'super_admin']}
        />
        <Route path="/agency/sign-up" component={AgencySignUpPage} />
        <PrivateRoute path="/agency" component={AgencyHomePage} />
      </Switch>
    </>
  );
}
