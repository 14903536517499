import { call, put, takeLatest } from 'redux-saga/effects';
import { universityFormActions as actions } from './index';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  DeleteAction,
  GetAction,
  InitialValues,
  SaveAction,
  SaveBody,
} from './types';
import { FileResponse } from '../../../../../../types/Dict';
import { uploadFile } from '../../../../../../services/files';
import { AxiosResponse } from 'axios';
import { api } from '../../../../../../services/api';
import { University } from '../../../../../../types/University';
import { toOption } from '../../../../../../utils/to-options';

const getToInitialValues = (data: University): InitialValues => ({
  name: data.name,
  city: data.city,
  state: data.state,
  country: data.country ? toOption(data.country, ['id', 'name_en']) : undefined,
  total_enrollment: data.total_enrollment,
  school_website: data.school_website,
  about: data.about,
  cost_tuition: data.cost_tuition,
  cost_room: data.cost_room,
  cost_other: data.cost_other,
  cost_total: data.cost_total,
  academics_other: data.academics_other,
  academics_min_gpa: data.academics_min_gpa,
  academics_toefl: data.academics_toefl,
  academics_sat: data.academics_sat,
  academics_duolingo: data.academics_duolingo,
  logo_id: data.logo_id,
  logo: data.logo,
});

const valuesToBody = (values: InitialValues): SaveBody => ({
  name: values.name,
  city: values.city,
  state: values.state,
  country_id: values.country?.value || null,
  total_enrollment: values.total_enrollment,
  school_website: values.school_website,
  about: values.about,
  cost_tuition: values.cost_tuition,
  cost_room: values.cost_room,
  cost_other: values.cost_other,
  cost_total: values.cost_total,
  academics_other: values.academics_other,
  academics_min_gpa: values.academics_min_gpa,
  academics_toefl: values.academics_toefl,
  academics_sat: values.academics_sat,
  academics_duolingo: values.academics_duolingo,
  logo_id: values.logo_id,
});

function* saveUniversity(action: PayloadAction<SaveAction>) {
  const { universityId, values, resolve, reject } = action.payload;

  try {
    if (!values.logo) {
      values.logo_id = null;
    }
    // @ts-ignore
    else if (values.logo?.file) {
      const file: FileResponse | null = yield call(
        uploadFile,
        // @ts-ignore
        values.logo.file,
      );

      values.logo_id = file?.id || null;
    }

    const universityResponse: AxiosResponse<University> = yield call(
      universityId ? api.patch : api.post,
      universityId ? `university/${universityId}` : `university`,
      valuesToBody(values),
    );

    yield put(actions.saveSuccess());
    resolve(universityResponse.data);
  } catch (error) {
    yield put(actions.saveError());
    reject();
  }
}

function* getUniversity(action: PayloadAction<GetAction>) {
  try {
    const { universityId } = action.payload;
    const response: AxiosResponse<University> = yield call(
      api.get,
      `university/${universityId}`,
    );

    yield put(actions.getSuccess(getToInitialValues(response.data)));
  } catch (error) {
    yield put(actions.getError());
  }
}

function* deleteUniversity(action: PayloadAction<DeleteAction>) {
  const { universityId, resolve, reject } = action.payload;

  try {
    yield call(api.delete, `university/${universityId}`);

    yield put(actions.deleteSuccess());
    resolve({});
  } catch (error) {
    yield put(actions.deleteError());
    reject();
  }
}

export function* universityFormSaga() {
  yield takeLatest(actions.saveUniversity.type, saveUniversity);
  yield takeLatest(actions.getUniversity.type, getUniversity);
  yield takeLatest(actions.deleteUniversity.type, deleteUniversity);
}
