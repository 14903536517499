import React, { useContext } from 'react';
import { Formik } from 'formik';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import { PageWrapper } from '../../../../components/PageWrapper';
import { Col, Row } from '../../../../components/Grid';
import { Heading } from '../../../../components/Heading';
import { Article } from '../../../../components/Article';
import { Personal } from './Personal';
import { Professional } from './Professional';
import { Hr } from '../../../../components/Hr';
import { Button } from '../../../../components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { selectCoachProfileForm } from './slice/selectors';
import { useCoachProfileSlice } from './slice';
import { AuthContext } from '../../../../../context/AuthContext';
import {
  emailSchema,
  fileSchema,
  optionSchema,
  stringSchema,
  stringSchemaOptional,
} from '../../../../../utils/validation-schemas';
import { Program } from '../../../../../types/Program';
import { University } from '../../../../../types/University';
import { InitialValues } from './slice/types';
import { CoachProfile } from '../../../../../types/Coach';

const validationSchema = Yup.object().shape({
  profile: Yup.object().shape({
    photo: fileSchema,
    coach_type: optionSchema,
    university: optionSchema,
    contact_email: emailSchema,
    contact_phone: stringSchema,
    sport: optionSchema,
    bio: stringSchemaOptional,
    program: optionSchema,
    previous_experience: stringSchemaOptional,
    alma_mater: stringSchemaOptional,
    /*formation: optionSchema,*/
    coaching_style: stringSchemaOptional,
  }),
  user: Yup.object().shape({
    first_name: stringSchema,
    last_name: stringSchema,
  }),
});

interface Props {
  sendForReview: boolean;
}

export function Profile(props: Props) {
  const { sendForReview } = props;
  const { t } = useTranslation();
  const { initialValues, getProfileLoading, getUserLoading } = useSelector(
    selectCoachProfileForm,
  );
  const { actions } = useCoachProfileSlice();
  const dispatch = useDispatch();
  const { user, getUserData } = useContext(AuthContext);
  const coachId = user.id;
  const history = useHistory<{ university?: University; program?: Program }>();
  const university = history?.location?.state?.university;
  const program = history?.location?.state?.program;

  let profileInitialValues = { ...initialValues.profile };
  let userInitialValues = { ...initialValues.user };

  useMount(() => {
    dispatch(actions.getProfile({ coachId }));
    dispatch(actions.getUser({ coachId }));
  });

  if (university) {
    profileInitialValues.university = {
      value: university.id,
      label: university.name,
    };
  }

  if (program) {
    profileInitialValues.program = {
      value: program.id,
      label: program.name,
    };
  }

  if (!profileInitialValues.contact_email) {
    profileInitialValues.contact_email = user?.email || '';
  }

  const saveProfile = (values: InitialValues): Promise<CoachProfile> =>
    new Promise((resolve, reject) =>
      dispatch(
        actions.saveProfile({
          coachId,
          values,
          resolve,
          reject,
        }),
      ),
    );

  const onSubmit = async values => {
    try {
      const coach = await saveProfile(values);
      await getUserData();
      if (sendForReview) {
        dispatch(actions.sendForReview({ coachId }));
      }
      history.push(`/coach/${coach.user_id}/profile`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PageWrapper withNavigation loading={getProfileLoading || getUserLoading}>
      <Row flexDirection="column">
        <Col>
          <Heading>{t('title.yourProfile')}</Heading>
        </Col>
        <Col gutterY={2}>
          <Article>
            <Formik
              initialValues={{
                profile: profileInitialValues,
                user: userInitialValues,
              }}
              enableReinitialize
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, isSubmitting, errors }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Row gutterY={2}>
                      <Col xs={12} gutterY={2}>
                        <Personal saveProfile={saveProfile} />
                      </Col>
                      <Col xs={12} gutterY={2}>
                        <Hr />
                      </Col>
                      <Col xs={12} gutterY={2}>
                        <Professional />
                      </Col>
                      <Col xs={12} gutterY={2}>
                        <Hr />
                      </Col>
                      <Col xs={12} gutterY={2}>
                        <Button primary $loading={isSubmitting} type="submit">
                          {sendForReview
                            ? t('button.requestAccess')
                            : t('button.saveChanges')}
                        </Button>
                      </Col>
                    </Row>
                  </form>
                );
              }}
            </Formik>
          </Article>
        </Col>
      </Row>
    </PageWrapper>
  );
}

Profile.defaultProps = {
  sendForReview: false,
};
