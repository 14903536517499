import { User } from '../types/User';
import { PermissionType, RoleTagType } from '../types/Dict';

interface validateUserPermissionsParams {
  user: User;
  permissions?: PermissionType[];
  roles?: string[];
  tags?: RoleTagType[];
}

export function validateRoutePermissions({
  user,
  permissions,
  tags,
}: validateUserPermissionsParams) {
  let hasAllPermissions = true;
  let hasAllTags = true;

  if (permissions?.length) {
    hasAllPermissions = validateUserPermissions(user, permissions);
  }

  if (tags?.length) {
    hasAllTags = validateUserTags(user, tags);
  }

  return { hasAllPermissions, hasAllTags };
}

export function validateUserTags(user: User, tags: RoleTagType[]): boolean {
  return tags.includes(user?.role?.tag);
}

export function validateUserPermissions(
  user: User,
  permissions: PermissionType[],
): boolean {
  return permissions.every(permission => {
    return (user?.role.permissions || {})[permission];
  });
}
