import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from './index';

const selectSlice = (state: RootState) => state.universityForm || initialState;

export const selectUniversityForm = createSelector(
  [selectSlice],
  state => state,
);
