import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Heading } from '../../../../../components/Heading';
import { Col, Row } from '../../../../../components/Grid';
import {
  AsyncSelectField,
  FileField,
  NumberField,
  TextAreaField,
  TextField,
} from '../../../../../components/Fields';

export function Overview(props) {
  const { t } = useTranslation();

  return (
    <>
      <Row gutterY={2}>
        <Col xs={12} gutterY={2}>
          <Heading level={2}>{t('title.overview')}</Heading>
        </Col>
        <Col xs={12} gutterY={2}>
          <FileField
            label={t(`button.upload__name`, {
              name: t('label.universityLogo'),
            })}
            name="logo"
            border
          />
        </Col>
        <Col xs={12} gutterY={2}>
          <Row gutter={2} gutterY={2}>
            <Col xs={4} gutter={2} gutterY={2}>
              <TextField label={t('label.universityName')} name="name" />
            </Col>
          </Row>
        </Col>
        <Col xs={12} gutterY={2}>
          <Row gutter={2} gutterY={2}>
            <Col xs={8} gutter={2} gutterY={2}>
              <Row gutter={2} gutterY={2}>
                <Col xs={6} gutter={2} gutterY={2}>
                  <TextField label={t('label.city')} name="city" />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <NumberField
                    label={t('label.totalEnrollment')}
                    name="total_enrollment"
                    thousandSeparator
                  />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <TextField label={t('label.state')} name="state" />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <TextField
                    label={t('label.universityWebsite')}
                    name="school_website"
                  />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <AsyncSelectField
                    label={t('label.country')}
                    name="country"
                    source="/countries"
                    optionKeys={['id', 'name_en']}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={4} gutter={2} gutterY={2}>
              <TextAreaField
                label={t('label.aboutUniversity')}
                name="about"
                minRows={10}
                placeholder={t('placeholder.aboutUniversity')}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
