import React from 'react';
import styled from 'styled-components';
import OriginAsyncCreatableSelect from 'react-select/async-creatable';

import { selectStyles } from './styles';
import { DropdownIndicator } from './components';

export const AsyncCreatableSelect = props => {
  const {
    options = [],
    value,
    onChange,
    name,
    loadOptions,
    onCreateOption,
    isLoading,
    defaultOptions,
  } = props;

  const optionValue = options.find(option => option.value === value);

  return (
    <StyledAsyncCreatableSelect
      classNamePrefix="react-select"
      options={options}
      value={optionValue}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: DropdownIndicator,
      }}
      onChange={(option: any) => {
        onChange(name, option.value);
      }}
      loadOptions={loadOptions}
      onCreateOption={onCreateOption}
      isLoading={isLoading}
      defaultOptions={defaultOptions}
    />
  );
};

const StyledAsyncCreatableSelect = styled(OriginAsyncCreatableSelect)`
  ${selectStyles}
`;
