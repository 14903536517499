import { createGlobalStyle } from 'styled-components';
import { IconWrapper } from '../app/components/Icon';

export const GlobalStyle = createGlobalStyle`
  :where(:root) {
    overflow-wrap: normal;
  }

  :root {
    --white: #FDFDFD;
    --white-rgb: 253, 253, 253;

    --black: #000000;
    --black-rgb: 0, 0, 0;

    --gold-100: #393022;
    --gold-90: #4C3D27;
    --gold-50: #866537;
    --gold-30: #BF904E;
    --gold-20: #D2B183;
    --gold-0: #E5D3B8;

    --grey-850: #1A1A1A;
    --grey-800: #212121;
    --grey-750: #292929;
    --grey-700: #353535;
    --grey-600: #4E4E4E;
    --grey-500: #686868;
    --grey-400: #848484;
    --grey-300: #A1A1A1;

    --red: #F15A5A;
    --yellow: #FFD53F;
    --green: #00AA1B;
    --blue: #0F6FFF;
  }

  * {
    outline: none;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    background: var(--grey-850);
    color: var(--white);
    font-size: 16px;
  }

  html {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 1180px;
  }

  body {
    font-size: 16px;
    line-height: 22px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;

    &.fontLoaded {
      font-family: 'Work Sans', Helvetica, Arial, sans-serif;
    }
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  p {
    margin: 0;

    & + & {
      margin-top: 1rem;
    }
  }

  ul {
    padding: 0;
    margin: 0 0 0 1rem;

    li {
      margin-top: 0.5rem;
    }
  }

  .react-select__menu-portal {
    z-index: 3 !important;

    .react-select__menu {
      background: var(--grey-700);
      border-radius: 4px;
      line-height: 22px;
      font-size: 16px;
      overflow: hidden;
      margin: 0.25rem 0 0 0;
      padding: 0.25rem;
      min-width: 100%;
      width: auto;
      white-space: nowrap;
      box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
    }

    .react-select__menu-list {
      padding: 0;
    }

    .react-select__option {
      padding: 0.75rem 3rem 0.75rem 0.75rem;
      position: relative;
      border-radius: 4px;

      ${IconWrapper} {
        position: absolute;
        right: 0.75rem;
        top: 50%;
        margin-top: -0.75rem;
        display: none;
      }

      &.react-select__option--is-focused {
        background: var(--grey-600) !important;
      }

      &.react-select__option--is-selected {
        background: transparent;
        color: var(--gold-30);
        font-weight: 600;

        ${IconWrapper} {
          display: block;
        }
      }
    }
  }
`;
