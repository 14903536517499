import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { universityFormSaga } from './saga';
import {
  DeleteAction,
  GetAction,
  InitialValues,
  SaveAction,
  UniversityFormState,
} from './types';

export const initialState: UniversityFormState = {
  initialValues: {
    name: '',
    city: '',
    state: '',
    country: undefined,
    total_enrollment: null,
    school_website: '',
    about: '',
    cost_tuition: null,
    cost_room: null,
    cost_other: null,
    cost_total: null,
    academics_other: '',
    academics_min_gpa: null,
    academics_toefl: null,
    academics_sat: null,
    academics_duolingo: null,
    logo_id: null,
    logo: null,
  },
  getLoading: false,
  saveLoading: false,
  deleteLoading: false,
};

const slice = createSlice({
  name: 'universityForm',
  initialState,
  reducers: {
    saveUniversity(state, action: PayloadAction<SaveAction>) {
      state.saveLoading = true;
    },
    saveSuccess(state) {
      state.saveLoading = false;
    },
    saveError(state) {
      state.saveLoading = false;
    },

    getUniversity(state, action: PayloadAction<GetAction>) {
      state.getLoading = true;
    },
    getSuccess(state, action: PayloadAction<InitialValues>) {
      state.initialValues = action.payload;
      state.getLoading = false;
    },
    getError(state) {
      state.getLoading = false;
    },

    resetUniversity(state) {
      state.initialValues = initialState.initialValues;
    },

    deleteUniversity(state, action: PayloadAction<DeleteAction>) {
      state.deleteLoading = true;
    },
    deleteSuccess(state) {
      state.deleteLoading = false;
    },
    deleteError(state) {
      state.deleteLoading = false;
    },
  },
});

export const { actions: universityFormActions } = slice;

export const useUniversityFormSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: universityFormSaga });
  return { actions: slice.actions };
};
