import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import FontFaceObserver from 'fontfaceobserver';
import { useDispatch, useSelector } from 'react-redux';

import { GlobalStyle } from 'styles/global-styles';

import { Routes } from './routes';
import { useDictSlice } from './slice';
import { selectLoaded } from './slice/selectors';
import { AuthContext } from '../context/AuthContext';
import { useWatchlistSlice } from './pages/WatchlistPage/slice';

const fontObserver = new FontFaceObserver('Work Sans', {});

fontObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

export function App() {
  const { i18n } = useTranslation();
  const { isAuthenticated, user } = React.useContext(AuthContext);
  const dispatch = useDispatch();
  const loaded = useSelector(selectLoaded);
  const { actions: dictActions } = useDictSlice();
  const { actions: watchlistActions } = useWatchlistSlice();

  React.useEffect(() => {
    if (isAuthenticated && !loaded) {
      dispatch(dictActions.getDict());
      dispatch(watchlistActions.getIds());
    }
  }, [isAuthenticated, user?.id]);

  return (
    <>
      <Helmet
        titleTemplate="%s - Relocate"
        defaultTitle="Relocate by Matchmetrics"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="" />
      </Helmet>
      <Routes />
      <GlobalStyle />
    </>
  );
}
