import React, { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { PrivateRoute } from '../../routes/PrivateRoute';
import { PlayerHomePage } from './PlayerHomePage/Loadable';
import { PlayerSignUpPage } from './PlayerSignUpPage/Loadable';
import { PlayerProfilePage } from './PlayerProfilePage/Loadable';
import { PlayerEditPage } from './PlayerEditPage/Loadable';
import { usePlayerToDoSlice } from './slice';
import { AuthContext } from '../../../context/AuthContext';
import { PlayerOnBoardingPage } from './PlayerOnBoardingPage/Loadable';

export function PlayerPage(props) {
  const { actions } = usePlayerToDoSlice();
  const dispatch = useDispatch();
  const { isAuthenticated } = React.useContext(AuthContext);
  const params = useParams<{ playerId: string }>();
  const playerId = +params.playerId;

  useEffect(() => {
    if (playerId) {
      dispatch(actions.getAllProgress({ playerId }));
      dispatch(actions.getPlayerUser({ playerId }));
    }
  }, [playerId, isAuthenticated]);

  return (
    <Switch>
      <Route path="/player/:playerId/sign-up" component={PlayerSignUpPage} />
      <Route
        path="/player/:playerId/onboarding"
        component={PlayerOnBoardingPage}
      />
      <Route
        path="/player/:playerId/onboarding"
        component={PlayerOnBoardingPage}
      />
      <PrivateRoute path="/player/:playerId/edit" component={PlayerEditPage} />
      <PrivateRoute
        path="/player/:playerId/profile"
        component={PlayerProfilePage}
      />
      <PrivateRoute exact path="/player/:playerId" component={PlayerHomePage} />
    </Switch>
  );
}
