import styled, { css } from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import TextareaAutosize from 'react-textarea-autosize';

interface TextareaProps {
  invalid?: boolean;
  minRows?: number;
  maxRows?: number;
}

export const textareaStyles = css`
  background: var(--grey-700);
  padding: 11px 0.75rem;
  font-size: 16px;
  line-height: 22px;
  color: var(--white);
  border-radius: 0.25rem;
  border: 1px solid ${ifProp('invalid', 'var(--red)', 'transparent')};
  font-feature-settings: 'tnum' on, 'onum' on;
  width: 100%;
  outline: none;

  &:hover {
    border-color: var(--grey-600);
    transition: background-color 0.2s, border-color 0.2s;
  }

  &:focus {
    background-color: var(--grey-600);
    border-color: var(--grey-600);
    transition: background-color 0.2s, border-color 0.2s;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--grey-700) inset;
    -webkit-text-fill-color: var(--grey-700);
    border: 1px solid var(--grey-700) !important;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: #fff !important;
  }

  &::placeholder {
    color: var(--grey-400);
    opacity: 1;
  }

  &[readonly] {
    border-color: var(--grey-700) !important;
  }
`;

export const Textarea = styled(TextareaAutosize)<TextareaProps>`
  ${textareaStyles}
`;
