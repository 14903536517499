import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { AuthContext } from '../../../context/AuthContext';

export function HomePage() {
  const { user } = React.useContext(AuthContext);

  switch (user?.role.tag) {
    case 'player':
      return <Redirect to={`/player/${user.id}`} />;
    case 'coach':
      return <Redirect to="/coach" />;
    case 'agency_member':
    case 'agency_owner':
    case 'super_admin':
      return <Redirect to="/agency" />;
  }

  return null;
}
