import * as React from 'react';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { useTranslation } from 'react-i18next';
import Dotdotdot from 'react-dotdotdot';
import { useSelector } from 'react-redux';

import { CircleImage } from '../CircleImage';
import { Heading } from '../Heading';
import { Info } from '../Info';
import { Col, Row } from '../Grid';
import { PlayerProfile } from '../../../types/Player';
import { AuthContext } from '../../../context/AuthContext';
import { getFileLink } from '../../../utils/get-file-link';
import { Ellipsis } from '../Ellipsis';
import { PlayerCardWrapper } from '../PlayerCardWrapper';
import { Span } from '../Span';
import { selectPreferences, selectSemesters } from '../../slice/selectors';

interface Props {
  player: PlayerProfile | null;
}

export function PlayerCard(props: Props) {
  const { t } = useTranslation();
  const { player } = props;
  const { accessToken } = React.useContext(AuthContext);
  const preferences = useSelector(selectPreferences);
  const semesters = useSelector(selectSemesters);

  const {
    first_name = '',
    last_name = '',
    player_positions,
    player_commitment,
    career,
    player_preferences,
  } = player || {};
  const isCommitted = player_commitment?.is_committed || false;

  const startDatePreference = preferences.find(
    ({ varname }) => varname === 'start_date',
  );
  const playerStartDate = (player_preferences || []).find(
    ({ preference_id }) => preference_id === startDatePreference?.id,
  );
  const semester = semesters.find(
    ({ id }) => id === +(playerStartDate?.value || 0),
  );
  const availableFrom = semester?.name_en || null;

  return (
    <PlayerCardWrapper player={player}>
      <Row flexDirection="column" height="100%">
        <ColWithMaxWidth xs="auto" gutterY={1}>
          <Row flexDirection="column">
            <ColWithMaxWidth>
              <Heading level={3}>
                <Ellipsis>
                  {first_name} {last_name}
                </Ellipsis>
              </Heading>
            </ColWithMaxWidth>
            <ColWithMaxWidth gutterY={0.25}>
              <Info>
                <Ellipsis>
                  {player_positions?.position?.description || ''}
                </Ellipsis>
              </Info>
            </ColWithMaxWidth>
            {career ? (
              <ColWithMaxWidth gutterY={0.75}>
                <div>
                  <Ellipsis>
                    <Span level={2}>{career?.team?.name_en || ''}</Span>
                  </Ellipsis>
                </div>
                <div>
                  <Ellipsis>
                    <Span level={2}>{career?.competition?.name_en || ''}</Span>
                  </Ellipsis>
                </div>
              </ColWithMaxWidth>
            ) : null}
          </Row>
        </ColWithMaxWidth>
        {isCommitted ? (
          <ColWithMaxWidth gutterY={0.75}>
            <Info level={2} color="grey" style={{ minHeight: '2rem' }}>
              <Dotdotdot clamp={2}>
                {t('label.committedTo', {
                  name: player_commitment?.program?.name || '',
                })}
              </Dotdotdot>
            </Info>
          </ColWithMaxWidth>
        ) : null}
        {!isCommitted && availableFrom ? (
          <ColWithMaxWidth gutterY={0.75}>
            <Info level={2} color="gold" style={{ minHeight: '2rem' }}>
              <div>{t('label.availableFrom')}</div>
              <div>{availableFrom}</div>
            </Info>
          </ColWithMaxWidth>
        ) : null}
        <Col gutterY={1}>
          <ImagesWrapper>
            <PlayerImage
              imageSrc={getFileLink(player?.player_profile?.photo, accessToken)}
              size={156}
              grayscale={isCommitted}
            />
            {isCommitted && player?.player_commitment?.program?.logo ? (
              <ProgramImage
                imageSrc={getFileLink(
                  player.player_commitment.program.logo,
                  accessToken,
                )}
                size={64}
              />
            ) : null}
          </ImagesWrapper>
        </Col>
      </Row>
    </PlayerCardWrapper>
  );
}

const ColWithMaxWidth = styled(Col)`
  max-width: 100%;
`;

const ImagesWrapper = styled.div`
  line-height: 0;
  position: relative;
  margin: 0 auto;
  width: 156px;
`;

const PlayerImage = styled(CircleImage)<{ grayscale?: boolean }>`
  filter: grayscale(${ifProp('grayscale', 1, 0)});
`;

const ProgramImage = styled(CircleImage)`
  position: absolute;
  right: 0;
  bottom: 0;
`;
