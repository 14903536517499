import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import reportWebVitals from 'reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ModalProvider } from 'styled-react-modal';
import { LastLocationProvider } from 'react-router-last-location';
import { ModalBackground } from './app/components/Modal';
import { AuthProvider } from './context/AuthContext';
import { configureAppStore } from 'store/configureStore';
import { UploadsProvider } from './context/UploadsContext';

import 'sanitize.css/assets.css';
import 'sanitize.css/forms.css';
import 'sanitize.css/sanitize.css';
import 'react-tooltip/dist/react-tooltip.css';

import { App } from 'app';

import './locales/i18n';

const store = configureAppStore();
const MOUNT_NODE = document.getElementById('root') as HTMLElement;

ReactDOMClient.createRoot(MOUNT_NODE!).render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <LastLocationProvider>
          <AuthProvider>
            <UploadsProvider>
              <ModalProvider backgroundComponent={ModalBackground}>
                <App />
              </ModalProvider>
            </UploadsProvider>
          </AuthProvider>
        </LastLocationProvider>
      </BrowserRouter>
    </HelmetProvider>
  </Provider>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {});
}

reportWebVitals();

(() => {
  try {
    if (process.env.REACT_APP_SHOW_INTERCOM === 'true') {
      //@ts-ignore
      window.onUsersnapCXLoad = function (api) {
        api.init();
      };
      const script = document.createElement('script');
      script.async = true;
      script.src =
        'https://widget.usersnap.com/load/54857763-1d8d-47ef-9438-6d4e806b5377?onload=onUsersnapCXLoad';
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  } catch (err: any) {
    console.error(err);
  }
})();
