import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { GetAction, ProfileForm, SaveAction, SaveBody } from './types';
import { coachProfileFormActions as actions } from './index';
import { api } from '../../../../../../services/api';
import { FileResponse } from '../../../../../../types/Dict';
import { uploadFile } from '../../../../../../services/files';
import { selectCoachProfileForm } from './selectors';
import { CoachProfile } from '../../../../../../types/Coach';
import { toOption } from '../../../../../../utils/to-options';
import { User } from '../../../../../../types/User';
import { UserForm } from '../../../../Player/forms/Personal/slice/types';

const valuesToBody = (values: ProfileForm): SaveBody => ({
  photo_id: values.photo_id || null,
  contact_email: values.contact_email,
  contact_phone: values.contact_phone,
  coach_type_id: values.coach_type?.value || null,
  sport_id: values.sport?.value || null,
  bio: values.bio,
  previous_experience: values.previous_experience,
  alma_mater: values.alma_mater,
  formation_id: values.formation?.value || null,
  coaching_style: values.coaching_style,
  program_id: values.program?.value || null,
  university_id: values.university?.value || null,
});

const getToInitialValues = (data: CoachProfile): ProfileForm => ({
  photo_id: data.photo_id || null,
  photo: data.photo || null,
  coach_type: data.coach_type
    ? toOption(data.coach_type, ['id', 'name'])
    : undefined,
  university: data.university
    ? toOption(data.university, ['id', 'name'])
    : undefined,
  contact_email: data.contact_email,
  contact_phone: data.contact_phone,
  sport: data.sport ? toOption(data.sport, ['id', 'name']) : undefined,
  bio: data.bio,
  program: data.program ? toOption(data.program, ['id', 'name']) : undefined,
  previous_experience: data.previous_experience,
  alma_mater: data.alma_mater,
  formation: data.formation
    ? toOption(data.formation, ['id', 'name'])
    : undefined,
  coaching_style: data.coaching_style,
});

function* getProfile(action: PayloadAction<GetAction>) {
  try {
    const { coachId } = action.payload;
    const response: AxiosResponse<CoachProfile> = yield call(
      api.get,
      `coach/${coachId}/profile`,
    );

    yield put(actions.getProfileSuccess(getToInitialValues(response.data)));
  } catch (error) {
    yield put(actions.getProfileError(error));
  }
}

function* getUser(action: PayloadAction<GetAction>) {
  try {
    const { coachId } = action.payload;
    const response: AxiosResponse<User> = yield call(
      api.get,
      `users/${coachId}`,
    );

    yield put(
      actions.getUserSuccess({
        first_name: response.data.first_name,
        last_name: response.data.last_name,
      }),
    );
  } catch (error) {
    yield put(actions.getUserError(error));
  }
}

function* saveProfile(action: PayloadAction<SaveAction>) {
  const { coachId, values, resolve, reject } = action.payload;
  const { initialValues } = yield select(selectCoachProfileForm);

  try {
    let profile: ProfileForm = {
      ...initialValues.profile,
      ...values.profile,
    };
    const user: UserForm = {
      ...initialValues.user,
      ...values.user,
    };

    if (!profile.photo) {
      profile.photo_id = null;
    }
    // @ts-ignore
    else if (profile.photo?.file) {
      const file: FileResponse | null = yield call(
        uploadFile,
        // @ts-ignore
        values.profile.photo.file,
      );

      profile.photo_id = file?.id || null;
    }

    const profileResponse: AxiosResponse<CoachProfile> = yield call(
      api.post,
      `coach/${coachId}/profile`,
      valuesToBody(profile),
    );
    const userResponse: AxiosResponse<User> = yield call(
      api.patch,
      `users/${coachId}`,
      user,
    );

    yield put(
      actions.saveSuccess({
        personal: getToInitialValues(profileResponse.data),
        user: {
          first_name: userResponse.data.first_name,
          last_name: userResponse.data.last_name,
        },
      }),
    );

    resolve(profileResponse.data);

    // @ts-ignore
    if (profile.photo?.file && initialValues.personal.photo) {
      yield call(api.delete, `file/${coachId}/${initialValues.photo.id}`);
    }
  } catch (error) {
    console.error(error);
    yield put(actions.saveError(error));
    reject();
  }
}

function* sendForReview(action: PayloadAction<GetAction>) {
  try {
    const { coachId } = action.payload;

    yield call(api.post, `coach/${coachId}/review/send`, {
      is_ready_for_review: true,
    });
  } catch (error) {
    console.error(error);
  }
}

export function* coachProfileFormSaga() {
  yield takeLatest(actions.saveProfile.type, saveProfile);
  yield takeLatest(actions.getProfile.type, getProfile);
  yield takeLatest(actions.getUser.type, getUser);
  yield takeLatest(actions.sendForReview.type, sendForReview);
}
