import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../../routes/PrivateRoute';

import { NewUniversityPage } from './NewUniversityPage/Loadable';
import { EditUniversityPage } from './EditUniversityPage';

export function University() {
  return (
    <Switch>
      <PrivateRoute
        path="/university/create"
        component={NewUniversityPage}
        exact
      />
      <PrivateRoute
        path="/university/:universityId/edit"
        component={EditUniversityPage}
        exact
      />
    </Switch>
  );
}
