import * as React from 'react';

import { ProgramForm } from '../forms/Program';
import { useParams } from 'react-router-dom';
import { Navigation } from '../../../components/Navigation';

export function EditProgramPage() {
  const params = useParams<{ programId: string }>();
  const programId = +params.programId;

  return (
    <>
      <Navigation />
      <ProgramForm programId={programId} />
    </>
  );
}
