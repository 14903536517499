import Cookies from 'universal-cookie';

const cookies = new Cookies();

const ACCESS_TOKEN_COOKIE = 'auth.accessToken';
const REFRESH_TOKEN_COOKIE = 'auth.refreshToken';

export function createTokenCookies(accessToken: string, refreshToken: string) {
  cookies.set(ACCESS_TOKEN_COOKIE, accessToken, {
    path: '/',
  });
  cookies.set(REFRESH_TOKEN_COOKIE, refreshToken, {
    path: '/',
  });
}

export function removeTokenCookies() {
  cookies.remove(ACCESS_TOKEN_COOKIE, {
    path: '/',
  });
  cookies.remove(REFRESH_TOKEN_COOKIE, {
    path: '/',
  });
}

export function getAccessToken() {
  return cookies.get(ACCESS_TOKEN_COOKIE);
}

export function getRefreshToken() {
  return cookies.get(REFRESH_TOKEN_COOKIE);
}
