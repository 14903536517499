import React from 'react';
import styled from 'styled-components/macro';

import { Icon } from '../Icon';

interface PillProps {
  children: any;
  onClick?: () => void;
}

export function Pill(props: PillProps) {
  const { children, onClick } = props;

  return (
    <Wrapper onClick={onClick}>
      {children}
      <Icon name="cross" />
    </Wrapper>
  );
}

const Wrapper = styled.button`
  display: inline-block;
  border: 1px solid var(--gold-30);
  padding: 0.25rem 2.5rem 0.25rem 1rem;
  border-radius: 100px;
  position: relative;
  cursor: pointer;
  letter-spacing: 1px;

  &:hover {
    background: var(--gold-100);
    transition: background-color 0.2s;
  }

  svg {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    width: 1.5rem;
    height: 1.5rem;
    margin-top: -0.75rem;
  }
`;
