import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Heading } from '../../../../../components/Heading';
import { Col, Row } from '../../../../../components/Grid';
import {
  AsyncSelectField,
  SelectField,
  TextAreaField,
  TextField,
} from '../../../../../components/Fields';
import {
  selectConferenceOptions,
  selectDivisionOptions,
  selectSportOptions,
} from '../../../../../slice/selectors';

export function Overview(props) {
  const { t } = useTranslation();

  const sportOptions = useSelector(selectSportOptions);
  const divisionOptions = useSelector(selectDivisionOptions);
  const conferenceOptions = useSelector(selectConferenceOptions);

  return (
    <>
      <Row gutterY={2}>
        <Col xs={12} gutterY={2}>
          <Heading level={2}>{t('title.overview')}</Heading>
        </Col>
        <Col xs={12} gutterY={2}>
          <Row gutter={2} gutterY={2}>
            <Col xs={6} gutter={2} gutterY={2}>
              <Row gutter={2} gutterY={2}>
                <Col xs={6} gutter={2} gutterY={2}>
                  <TextField
                    label={t('label.nameOfAthleticProgram')}
                    name="overview.name"
                  />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <SelectField
                    label={t('label.sports')}
                    name="overview.sport"
                    options={sportOptions}
                  />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <TextField
                    label={t('label.athleticsWebsite')}
                    name="overview.website"
                    placeholder="https://"
                  />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <SelectField
                    label={t('label.division')}
                    name="overview.division"
                    options={divisionOptions}
                  />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <AsyncSelectField
                    label={t('label.university')}
                    name="overview.university"
                    source="/university"
                    listKey="universities"
                    optionKeys={['id', 'name']}
                  />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <SelectField
                    label={t('label.conference')}
                    name="overview.conference"
                    options={conferenceOptions}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={6} gutter={2} gutterY={2}>
              <TextAreaField
                label={`${t('label.successes')}, ${t('label.awards')}, ${t(
                  'label.rankings',
                )}`}
                name="overview.awards"
                minRows={5}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
