import React from 'react';
import styled from 'styled-components';
import OriginalSelect from 'react-select';

import { multiSelectStyles } from './styles';
import {
  ClearIndicator,
  DropdownIndicator,
  MultiValueRemove,
  Option,
} from './components';

export const MultiSelect = props => {
  const {
    options = [],
    value,
    onChange,
    name,
    onBlur,
    placeholder,
    invalid,
  } = props;

  return (
    <StyledSelect
      classNamePrefix="react-select"
      options={options}
      value={value}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator,
        ClearIndicator,
        MultiValueRemove,
        Option,
      }}
      onChange={onChange}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isMulti
      onBlur={onBlur}
      placeholder={placeholder}
      isClearable={false}
      invalid={invalid}
    />
  );
};

const StyledSelect = styled(OriginalSelect)<{
  border?: boolean;
  invalid?: boolean;
}>`
  ${multiSelectStyles}
`;
