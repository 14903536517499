import { AxiosResponse } from 'axios';

import { api } from './api';
import { FileResponse } from '../types/Dict';
import { UploadsManager } from './uploads-manager';
import { getUploadId } from '../utils/get-upload-id';

export async function uploadFile(file: File): Promise<FileResponse> {
  const uploadId = getUploadId(file);
  const formData = new FormData();
  formData.append('file', file, file.name);

  UploadsManager.addUpload(uploadId);
  const response: AxiosResponse<FileResponse> = await api.post(
    'file',
    formData,
  );
  UploadsManager.removeUpload(uploadId);

  return response.data;
}

const files = {
  uploadFile,
};

export default files;
