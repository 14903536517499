import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

import { NotFoundPage } from '../pages/NotFoundPage/Loadable';
import { AuthPage } from '../pages/AuthPage/Loadable';
import { PlayerPage } from '../pages/Player';
import { HomePage } from '../pages/HomePage';
import { AgencyPage } from '../pages/Agency';
import { SettingsPage } from '../pages/SettingsPage/Loadable';
import { Coach } from '../pages/Coach';
import { WatchlistPage } from '../pages/WatchlistPage/Loadable';
import { SearchRequest } from '../pages/SearchRequest/Loadable';
import { University } from '../pages/University';
import { Program } from '../pages/Program';
import { CoachesPage } from '../pages/CoachesPage/Loadable';
import { ProgramsPage } from '../pages/ProgramsPage/Loadable';

export const Routes = () => (
  <Switch>
    <PublicRoute path="/auth" component={AuthPage} />
    <Route path="/player/:playerId" component={PlayerPage} />
    <Route path="/agency" component={AgencyPage} />
    <PrivateRoute path="/settings" component={SettingsPage} />
    <Route path="/coach" component={Coach} />
    <PrivateRoute path="/watchlist" component={WatchlistPage} />
    <PrivateRoute path="/search-request" component={SearchRequest} />
    <PrivateRoute path="/university" component={University} />
    <PrivateRoute path="/program" component={Program} />
    <PrivateRoute path="/coaches" component={CoachesPage} />
    <PrivateRoute path="/programs" component={ProgramsPage} />

    <PrivateRoute exact path="/" component={HomePage} />

    <PublicRoute component={NotFoundPage} />
  </Switch>
);
