import styled from 'styled-components/macro';
import { switchProp } from 'styled-tools';
import { css } from 'styled-components';

interface Props {
  level?: 1 | 2;
  color?: 'red' | 'grey' | 'gold';
}

export const Info = styled.div<Props>`
  letter-spacing: 0.8px;
  text-transform: uppercase;

  color: ${switchProp('color', {
    red: 'var(--red)',
    grey: 'var(--grey-300)',
    gold: 'var(--gold-30)',
  })};

  ${switchProp('level', {
    1: css`
      font-size: 13px;
      line-height: 18px;
      font-weight: 500;
    `,
    2: css`
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    `,
  })};
`;

Info.defaultProps = {
  level: 1,
};
