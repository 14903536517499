import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FilterSelect } from '../Select';
import {
  CoachSortingType,
  PlayerSortingType,
  SortingTranslation,
  UserSortingType,
} from '../../../types/Dict';
import { selectPlayerSortingFields } from '../../slice/selectors';
import { SortBy } from '../../../types/Filter';

interface Props {
  fields: (PlayerSortingType | UserSortingType | CoachSortingType)[];
  sortBy: SortBy;
  sortDirection: string;
  onSortChange: ({ sortBy: SortBy, sortDirection: string }) => void;
}

export function PlayerSort(props: Props) {
  const { t } = useTranslation();
  const { fields, sortBy, sortDirection, onSortChange } = props;
  const playerSortingFields = useSelector(selectPlayerSortingFields);

  const options = fields.map(field => {
    const type = PlayerSortingType[field];
    const translationKey = SortingTranslation[type];
    const fieldName = playerSortingFields[type];

    return {
      label: t(translationKey),
      value: fieldName,
    };
  });

  const onChange = sortBy => {
    onSortChange({
      sortBy,
      sortDirection: sortBy === PlayerSortingType.DEFAULT ? 'desc' : 'asc',
    });
  };

  return (
    <FilterSelect
      options={options}
      placeholder={t('placeholder.sort')}
      isSearchable={false}
      value={sortBy}
      onChange={onChange}
      closeMenuOnSelect
    />
  );
}
