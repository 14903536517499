import { call, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

import { coachPlayersActions as actions } from '.';
import { api } from '../../../../../services/api';
import { GetPlayersResponse } from './types';
import {
  selectFilterRequest,
  selectSortBy,
  selectSortDirection,
} from './selectors';

function* getPlayers(action: PayloadAction<any>) {
  try {
    const {
      take = 40,
      skip = 0,
      promiseResolver = () => {},
    } = action.payload || {};
    const filterRequest = yield select(selectFilterRequest);
    const sortBy = yield select(selectSortBy);
    const sortDirection = yield select(selectSortDirection);

    const response: AxiosResponse<GetPlayersResponse> = yield call(
      api.get,
      `/player`,
      {
        params: {
          take,
          skip,
          sort: sortBy.value,
          sort_direction: sortDirection,
          ...filterRequest,
        },
      },
    );
    if (skip === 0) yield put(actions.resetPlayers());
    yield put(actions.getSuccess(response));
    promiseResolver && promiseResolver();
  } catch (err) {
    const error = err as AxiosError;

    yield put(actions.getError(error));
  }
}

export function* coachPlayersSaga() {
  yield takeLatest(actions.getPlayers.type, getPlayers);
  yield takeLatest(actions.changeFilter.type, getPlayers);
  yield takeLatest(actions.clearFilter.type, getPlayers);
  yield takeLatest(actions.resetFilter.type, getPlayers);
  yield takeLatest(actions.changeSort.type, getPlayers);
}
