import React from 'react';
import { useTranslation } from 'react-i18next';

import { Pill } from '../Pill';
import { FilterType } from '../../../types/Filter';
import { numberFormat } from '../../../utils/number-format';

interface Props {
  name: [FilterType, string?];
  value: any;
  onClick: ([name, key]: [FilterType, string?]) => void;
}

export function FilterPill(props: Props) {
  const { name, value, onClick } = props;
  const { t } = useTranslation();

  let label = '';
  const count = value.length;

  if (Array.isArray(value) && value.length === 1) {
    label = value[0].label;
  } else if (value?.label) {
    label = value.label;
  } else {
    switch (name[0]) {
      case FilterType.AVAILABILITY: {
        label = t('filterValues.availability');
        break;
      }
      case FilterType.GENDER: {
        label = t('filterValues.gendersCount', { count });
        break;
      }
      case FilterType.START_DATE: {
        label = t('filterValues.startDatesCount', { count });
        break;
      }
      case FilterType.DIVISION: {
        label = t('filterValues.divisionsCount', { count });
        break;
      }
      case FilterType.POSITION: {
        label = t('filterValues.positionsCount', { count });
        break;
      }
      case FilterType.FOOT: {
        label = t('filterValues.footCount', { count });
        break;
      }
      case FilterType.ADMISSION: {
        label = t('filterValues.admissionsCount', { count });
        break;
      }
      case FilterType.SAT_DEGREE: {
        label = t('filterValues.satDegree', { value: value.join('–') });
        break;
      }
      case FilterType.TOEFL_SCORE: {
        label = t('filterValues.toeflScore', { value: value.join('–') });
        break;
      }
      case FilterType.DUOLINGO_SCORE: {
        label = t('filterValues.duolingoScore', { value: value.join('–') });
        break;
      }
      case FilterType.GRADE_POINT_AVG: {
        label = t('filterValues.gradePointAvg', { value: value.join('–') });
        break;
      }
      case FilterType.BUDGET: {
        label = t('filterValues.budget', {
          value: value.map(v => numberFormat.format(v)).join('–'),
        });
        break;
      }
      case FilterType.SPORT: {
        label = t('filterValues.sportCount', { count });
        break;
      }
    }
  }

  const onClickHandler = () => {
    onClick(name);
  };

  return <Pill onClick={onClickHandler}>{label}</Pill>;
}
