//export const toOptions<T> = (dict);

import { Option } from '../app/components/Select/types';

export function toOption<T extends object>(
  dict: T,
  [valueKey, labelKey]: [string, string],
): Option {
  if (!dict) return dict;
  return {
    value: dict[valueKey],
    label: dict[labelKey],
  };
}

export function toOptions<T extends object>(
  dicts: T[],
  [valueKey, labelKey]: [string, string],
): Option[] {
  if (!dicts) return [];
  return dicts.map(dict => toOption<T>(dict, [valueKey, labelKey]));
}
