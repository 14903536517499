import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Heading } from '../../../../../components/Heading';
import { Col, Row } from '../../../../../components/Grid';
import {
  SelectField,
  TextAreaField,
  TextField,
} from '../../../../../components/Fields';
import { selectFormationOptions } from '../../../../../slice/selectors';

export function Professional(props) {
  const { t } = useTranslation();
  const formationOptions = useSelector(selectFormationOptions);

  return (
    <Row>
      <Col xs={12}>
        <Heading level={2}>{t('title.professional')}</Heading>
      </Col>
      <Col xs={12} gutterY={2}>
        <Row gutter={2}>
          <Col xs={4} gutter={2}>
            <Row>
              <Col xs={12} gutterY={2}>
                <TextAreaField
                  label={t('label.previousSchoolsAndPositions')}
                  name="profile.previous_experience"
                  minRows={3}
                />
              </Col>
              <Col xs={12} gutterY={2}>
                <TextField
                  label={t('label.almaMater')}
                  name="profile.alma_mater"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={4} gutter={2}>
            <Row>
              <Col xs={12} gutterY={2}>
                <SelectField
                  label={t('label.preferredFormation')}
                  name="profile.formation"
                  options={formationOptions}
                />
              </Col>
              <Col xs={12} gutterY={2}>
                <TextAreaField
                  label={t('label.coachingStyle')}
                  name="profile.coaching_style"
                  minRows={3}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
