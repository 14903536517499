import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { AxiosError, AxiosResponse } from 'axios';

import { watchlistSaga } from './saga';
import {
  AddRequest,
  DeleteRequest,
  GetWatchlistResponse,
  WatchlistState,
} from './types';
import { Filters, FilterType, SortBy } from '../../../../types/Filter';
import { WatchlistPlayer } from '../../../../types/Watchlist';

export const initialFilterState: Filters = {
  [FilterType.AVAILABILITY]: null,
  [FilterType.GENDER]: [],
  [FilterType.START_DATE]: [],
  [FilterType.DIVISION]: [],
  [FilterType.POSITION]: [],
  [FilterType.FOOT]: null,
  [FilterType.ADMISSION]: [],
  [FilterType.SAT_DEGREE]: [0, 1600],
  [FilterType.TOEFL_SCORE]: [0, 120],
  [FilterType.DUOLINGO_SCORE]: [0, 160],
  [FilterType.GRADE_POINT_AVG]: [1, 4],
};

export const initialState: WatchlistState = {
  filter: initialFilterState,
  players: [],
  total: 0,
  loading: false,
  sortBy: {
    label: 'Default',
    value: 'user.created_at',
  },
  sortDirection: 'desc',
  playerIds: [],
};

const slice = createSlice({
  name: 'watchlist',
  initialState,
  reducers: {
    changeFilter(state, action: PayloadAction<Filters>) {
      state.filter = action.payload;
      state.loading = true;
    },
    clearFilter(state, action: PayloadAction<any>) {
      const [name, key] = action.payload;

      if (key) {
        delete state.filter[name][key];
      } else {
        state.filter[name] = initialState.filter[name];
      }

      state.loading = true;
    },
    resetFilter(state) {
      state.filter = initialState.filter;
      state.loading = true;
    },

    getPlayers(state, action: PayloadAction<any>) {
      if (!action.payload.skip) {
        state.loading = true;
      }
    },
    resetPlayers(state) {
      state.players = initialState.players;
      state.total = initialState.total;
    },
    getSuccess(
      state,
      action: PayloadAction<AxiosResponse<GetWatchlistResponse>>,
    ) {
      const { data, total } = action.payload.data;

      state.players = [...state.players, ...data.map(({ player }) => player)];
      state.total = total;
      state.loading = false;
    },
    getError(state, action: PayloadAction<AxiosError>) {
      state.loading = false;
    },

    changeSort(
      state,
      action: PayloadAction<{ sortBy: SortBy; sortDirection: string }>,
    ) {
      state.sortBy = action.payload.sortBy;
      state.sortDirection = action.payload.sortDirection;
      state.loading = true;
      //state.total = 0;
    },

    getIds(state) {},
    getIdsSuccess(state, action: PayloadAction<AxiosResponse<number[]>>) {
      state.playerIds = action.payload.data;
    },

    addToWatchlist(state, action: PayloadAction<AddRequest>) {
      state.playerIds = [...state.playerIds, action.payload.player_id];
    },
    addSuccess(state, action: PayloadAction<AxiosResponse<WatchlistPlayer>>) {
      const { player } = action.payload.data;
      state.players = [...state.players, player];
    },

    deleteFromWatchlist(state, action: PayloadAction<DeleteRequest>) {
      state.playerIds = state.playerIds.filter(
        id => id !== action.payload.player_id,
      );
    },
    deleteSuccess(state, action: PayloadAction<number>) {
      state.players = state.players.filter(
        player => player.id !== action.payload,
      );
    },
  },
});

export const { actions: watchlistActions } = slice;

export const useWatchlistSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: watchlistSaga });
  return { actions: slice.actions };
};
