import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { playerToDoActions as actions } from '.';
import { api } from '../../../../services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { GetAction } from './types';
import { PlayerProfile, PlayerValidation } from '../../../../types/Player';

function* getAllProgress(action: PayloadAction<GetAction>) {
  try {
    const { playerId } = action.payload;
    const response: AxiosResponse<PlayerValidation> = yield call(
      api.get,
      `player/${playerId}/validate`,
    );

    yield put(actions.getAllSuccess(response.data));
  } catch (error) {
    yield put(actions.getAllError());
  }
}

function* getPlayerUser(action: PayloadAction<GetAction>) {
  try {
    const { playerId } = action.payload;
    const response: AxiosResponse<PlayerProfile> = yield call(
      api.get,
      `player/${playerId}`,
    );

    yield put(actions.getPlayerUserSuccess(response.data));
  } catch (error) {
    yield put(actions.getPlayerUserError());
  }
}

export function* playerToDoSaga() {
  yield takeLatest(actions.getAllProgress.type, getAllProgress);
  yield takeLatest(actions.getPlayerUser.type, getPlayerUser);
}
