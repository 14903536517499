import * as React from 'react';
import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { css, keyframes } from 'styled-components';

import { Loader } from '../Loader';

interface Props {
  children?: any;
  loading?: boolean;
  withNavigation?: boolean;
}

export function PageWrapper(props: Props) {
  const { loading, children, withNavigation } = props;

  return (
    <Wrapper withNavigation={withNavigation}>
      {children}
      {loading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : null}
    </Wrapper>
  );
}

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(var(--black-rgb), 0.5);
  animation: ${fadeIn} 0.2s ease-out;
`;

const Wrapper = styled.div<{ withNavigation?: boolean }>`
  margin: 0 auto;
  padding: 4rem 1rem;
  width: calc(1280px + 7rem);
  min-width: calc(1084px + 7rem);
  max-width: 100%;

  ${ifProp(
    'withNavigation',
    css`
      padding-left: 6rem;
    `,
    '',
  )}
`;
