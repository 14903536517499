import React from 'react';
import raw from 'raw.macro';
import styled, { css } from 'styled-components/macro';
import { switchProp } from 'styled-tools';

export type IconName =
  | 'logo'
  | 'person'
  | 'binocular'
  | 'college'
  | 'whistle'
  | 'bookmark'
  | 'bookmark-thin'
  | 'faq'
  | 'settings'
  | 'log-out'
  | 'chevron'
  | 'checkmark'
  | 'cross'
  | 'weight'
  | 'height'
  | 'filters'
  | 'trash'
  | 'home'
  | 'sprint'
  | 'list'
  | 'grid'
  | 'dots'
  | 'question';

interface Props {
  name: IconName;
}

const fillIcons: IconName[] = [
  'home',
  'binocular',
  'college',
  'faq',
  'settings',
  'log-out',
  'bookmark-thin',
  'trash',
  'question',
  'filters',
  'grid',
  'list',
  'dots',
];

export const Icon = (props: Props) => {
  const { name } = props;

  const svg = raw(`./assets/${name}.svg`);
  const type = fillIcons.indexOf(name) !== -1 ? 'fill' : 'stroke';

  return (
    <IconWrapper
      type={type}
      {...props}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};

interface WrapperProps {
  readonly type: string;
}

export const IconWrapper = styled.span<WrapperProps>`
  line-height: 0;
  vertical-align: top;
  display: inline-block;

  svg {
    ${switchProp('type', {
      fill: css`
        fill: currentColor;
      `,
      stroke: css`
        stroke: currentColor;
      `,
    })}
  }
`;
