import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { api } from '../../services/api';
import { setAuthorizationHeader } from '../../services/interceptors';
import {
  createTokenCookies,
  getAccessToken,
  removeTokenCookies,
} from '../../utils/token-cookies';
import { User } from '../../types/User';

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  signIn: (credentials: SignInCredentials) => Promise<User | AxiosError>;
  signOut: () => void;
  getUserData: () => void;
  user: User;
  accessToken: string | null;
  isAuthenticated: boolean;
  loadingUserData: boolean;
}

interface AuthProviderProps {
  children: ReactNode;
}

interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  user: User;
}

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>();
  const [loadingUserData, setLoadingUserData] = useState(true);
  const history = useHistory();
  const { pathname } = useLocation();
  const accessToken = getAccessToken();
  const isAuthenticated = Boolean(user);
  const userData = user as User;
  const { i18n } = useTranslation();

  async function signIn(credentials: SignInCredentials) {
    try {
      const response: AxiosResponse<LoginResponse> = await api.post(
        '/auth/signin',
        credentials,
      );
      const { accessToken, refreshToken, user } = response.data;

      createTokenCookies(accessToken, refreshToken);
      setUser(user);
      i18n.changeLanguage(user?.language?.code || 'en');
      // @ts-ignore
      setAuthorizationHeader(api.defaults, accessToken);

      return user;
    } catch (err) {
      const error = err as AxiosError;
      return error;
    }
  }

  function signOut() {
    api.get(`/auth/logout`);
    flashUserData();
  }

  function flashUserData(pathname = '/auth/login') {
    removeTokenCookies();
    setUser(null);
    setLoadingUserData(false);
    setAuthorizationHeader(api.defaults, '');

    if (history.location.pathname.indexOf('/auth') === -1) {
      history.push(pathname);
    }
  }

  async function getUserData() {
    setLoadingUserData(true);

    try {
      const response: AxiosResponse<User> = await api.get('/auth/iam');
      const user = response.data;
      const { language } = user;

      if (user) {
        setUser(user);
        i18n.changeLanguage(language.code || 'en');
      }
    } catch (error) {
      flashUserData();
    }

    setLoadingUserData(false);
  }

  useEffect(() => {
    if (!accessToken) flashUserData(pathname);
  }, [pathname, accessToken]);

  useEffect(() => {
    const accessToken = getAccessToken();

    if (accessToken) {
      setAuthorizationHeader(api.defaults, accessToken);
      getUserData();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user: userData,
        accessToken,
        loadingUserData,
        getUserData,
        signIn,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
