import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';

import { PageWrapper } from '../../../components/PageWrapper';
import { Col, Row } from '../../../components/Grid';
import { initialFilterState, useCoachPlayersSlice } from './slice';
import {
  selectFilter,
  selectLoading,
  selectNormalizedFilter,
  selectPlayers,
  selectSortBy,
  selectSortDirection,
  selectTotal,
} from './slice/selectors';
import { WelcomeTitle } from '../components/WelcomeTitle';
import { AuthContext } from '../../../../context/AuthContext';
import { Hr } from '../../../components/Hr';
import { FiltersList } from '../../../components/FiltersList';
import { Separator } from '../../../components/Separator';
import { PlayerSelectedFilters } from '../../../components/PlayerSelectedFilters';
import { PlayerSort } from '../../../components/PlayerSort';
import { PlayerSortingType } from '../../../../types/Dict';
import { PlayersGrid } from '../../../components/PlayersGrid';
import { FilterType } from '../../../../types/Filter';
import { Navigation } from '../../../components/Navigation';

export function CoachPlayersPage() {
  const dispatch = useDispatch();
  const { actions } = useCoachPlayersSlice();
  const players = useSelector(selectPlayers);
  const filter = useSelector(selectFilter);
  const loading = useSelector(selectLoading);
  const normalizedFilter = useSelector(selectNormalizedFilter);
  const { user } = React.useContext(AuthContext);
  const sortBy = useSelector(selectSortBy);
  const sortDirection = useSelector(selectSortDirection);
  const total = useSelector(selectTotal);

  useEffectOnce(() => {
    dispatch(actions.getPlayers({}));
  });

  const onFilterSubmit = values => {
    dispatch(actions.changeFilter(values));
  };

  const onClearFilter = name => {
    dispatch(actions.clearFilter(name));
  };

  const onResetFilter = () => {};

  const onSortChange = ({ sortBy, sortDirection }) => {
    dispatch(actions.changeSort({ sortBy, sortDirection }));
  };

  const getPlayers = ({ take, skip, promiseResolver }) => {
    dispatch(actions.getPlayers({ take, skip, promiseResolver }));
  };

  return (
    <>
      <Navigation />
      <PageWrapper withNavigation loading={loading}>
        <Row flexDirection="column">
          <Col>
            <WelcomeTitle user={user} />
          </Col>
          <Col gutterY={4}>
            <Row gutter={3}>
              <Col gutter={3}>
                <PlayerSort
                  fields={[
                    PlayerSortingType.DEFAULT,
                    PlayerSortingType.FIRST_NAME,
                    PlayerSortingType.LAST_NAME,
                    PlayerSortingType.DIVISION,
                    PlayerSortingType.POSITION,
                  ]}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  onSortChange={onSortChange}
                />
              </Col>
              <Separator />
              <Col gutter={3} xs="auto">
                <FiltersList
                  initialValues={initialFilterState}
                  filter={filter}
                  onSubmit={onFilterSubmit}
                  fields={[
                    FilterType.AVAILABILITY,
                    FilterType.GENDER,
                    FilterType.START_DATE,
                    FilterType.DIVISION,
                    FilterType.POSITION,
                    FilterType.FOOT,
                    FilterType.ADMISSION,
                    FilterType.SAT_DEGREE,
                    FilterType.TOEFL_SCORE,
                    FilterType.DUOLINGO_SCORE,
                    FilterType.GRADE_POINT_AVG,
                  ]}
                />
              </Col>
            </Row>
          </Col>
          <Col gutterY={2}>
            <Hr />
          </Col>
          {Object.keys(normalizedFilter).length ? (
            <Col gutterY={2}>
              <PlayerSelectedFilters
                filter={normalizedFilter}
                onClearFilter={onClearFilter}
                onResetFilter={onResetFilter}
              />
            </Col>
          ) : null}
          <Col gutterY={4}>
            <PlayersGrid
              players={players}
              total={total}
              getPlayers={getPlayers}
            />
          </Col>
        </Row>
      </PageWrapper>
    </>
  );
}
