import { IconName } from '../app/components/Icon';

export type PermissionType =
  | 'access_roles'
  | 'edit_program'
  | 'invite_users'
  | 'list_coaches'
  | 'list_players'
  | 'manage_files'
  | 'manage_users'
  | 'delete_player'
  | 'edit_university'
  | 'manage_agencies'
  | 'manage_agency_users'
  | 'edit_player';

export type Permissions = {
  [key in PermissionType]: boolean;
};

export type RoleTagType =
  | 'super_admin'
  | 'player'
  | 'agency_member'
  | 'coach'
  | 'agency_owner'
  | 'trial_end';

export enum RoleId {
  SUPER_ADMIN = 1,
  PLAYER = 2,
  AGENCY_MEMBER = 3,
  COACH = 4,
  AGENCY_OWNER = 7,
  TRIAL_END = 8,
}

export interface Role {
  id: number;
  name: string;
  permissions: Permissions;
  tag: RoleTagType;
}

export interface Language {
  id: number;
  description: string;
  code: string;
  isactive: boolean;
}

export interface Agency {
  id: number;
  name: string;
  created_at: Date;
  updated_at: Date;
}

export interface Country {
  id: number;
  alpha2code: string;
  name_en: string;
}

export interface Position {
  id: number;
  abbreviation_en: string;
  abbreviation_de: string;
  description: string;
  upper_position_id: string;
  x: number;
  y: number;
}

export interface Season {
  id: number;
  name: string;
}

export interface Competition {
  id: number;
  name_en: string;
  country_id: number;
  country: Country;
}

export interface Team {
  id: number;
  name_en: string;
  country_id: number;
  country: Country;
}

export interface Social {
  id: number;
  name: string;
  base_url: string;
  varname: string;
}

export type SkillType = 'athletics' | 'strength' | 'rating';

export interface Skill {
  id: number;
  varname: string;
  name_en: string;
  description_en: string;
  unit: string;
  mandatory: boolean;
  type: SkillType;
  min_value: number;
  max_value: number;
  boundaries_min: number;
  boundaries_max: number;
  boundaries_avg: number;
  icon: IconName;
  description_coach_en: string;
}

export interface Region {
  id: number;
  name_en: string;
}

export interface Preference {
  id: number;
  varname: string;
  name_en: string;
  description_en: string;
  input_type: string;
  input_source: string;
  order: number;
  name_long_en: string;
}

export interface EducationDegree {
  id: number;
  name_en: string;
  order_rank: number;
  type: 'university' | 'school';
}

export interface BodyPart {
  id: number;
  name_en: string;
}

export interface Gender {
  id: number;
  name_en: string;
}

export interface FileResponse {
  id: number;
  key: string;
  src: string;
  original_name: string;
  size: string;
  user_id: number;
  created_at: string;
}

export interface LanguageTestType {
  id: number;
  name_en: string;
  name_de: string;
}

export interface Semester {
  id: number;
  name_en: string;
}

export interface Division {
  id: number;
  name: string;
  type: string;
}

export interface Admission {
  id: number;
  name_en: string;
  name_de: string;
  name: string;
}

export enum UserSortingType {
  DEFAULT,
  FIRST_NAME,
  LAST_NAME,
  SIGNUP_DATE,
  LAST_LOGGED_IN_DATE,
  EMAIL,
}

export enum UserSortingFields {
  FIRST_NAME = 'user.first_name',
  LAST_NAME = 'user.last_name',
  EMAIL = 'user.email',
}

export enum PlayerSortingType {
  DEFAULT,
  DIVISION,
  FIRST_NAME,
  LAST_LOGGED_IN_DATE,
  LAST_NAME,
  POSITION,
  SIGNUP_DATE,
  VALIDATION_TOTAL,
  STATUS,
  CLUB,
  COUNTRY,
}

export enum CoachSortingType {
  COACH_TYPE,
}

export enum ProgramsSortingType {
  NAME,
  CREATED_AT,
  UNIVERSITY,
  DIVISION,
  CONFERENCE,
  STATE,
  CITY,
  ENROLLMENT,
  SAT,
  DUOLINGO,
  TOEFL,
  GPA,
  COST,
}

export enum ProgramsSortingFields {
  NAME = 'program.name',
  CREATED_AT = 'program.created_at',
  UNIVERSITY = 'university.name',
  DIVISION = 'division.name',
  CONFERENCE = 'conference.name',
  STATE = 'university.state',
  CITY = 'university.city',
  ENROLLMENT = 'university.total_enrollment',
  SAT = 'university.academics_sat',
  DUOLINGO = 'university.academics_duolingo',
  TOEFL = 'university.academics_toefl',
  GPA = 'university.academics_min_gpa',
  COST = 'university.cost_total',
}

export enum SortingTranslation {
  DEFAULT = 'label.default',
  DIVISION = 'label.division',
  FIRST_NAME = 'label.firstName',
  LAST_LOGGED_IN_DATE = 'label.lastLoggedIn',
  LAST_NAME = 'label.lastName',
  POSITION = 'label.position',
  SIGNUP_DATE = 'label.signUp',
  VALIDATION_TOTAL = 'label.validation',
  SEMESTER = 'label.startDate',
  BUDGET = 'label.budget',
  COACH_TYPE = 'label.position',
  NAME = 'label.name',
  CREATED_AT = 'label.created',
  CITY = 'label.city',
  STATE = 'label.state',
}

export type PlayersSortingFields = {
  [key in PlayerSortingType]?: string;
};

export enum SortingOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export interface Priority {
  id: number;
  name_en: string;
}

export enum SearchRequestSortingType {
  ID = 'searchrequest.id',
  FULL_NAME = 'user.full_name',
  SUBMITTED = 'searchrequest.created_at',
  POSITION = 'position.abbreviation_en',
  DIVISION = 'division.name',
  SEMESTER = 'searchrequest.semester_id',
  BUDGET = 'searchrequest.budget',
  UNIVERSITY = 'university.name',
}

export type SearchRequestsSortingFields = {
  [key in SearchRequestSortingType]?: string;
};

export interface CoachType {
  id: number;
  name_en: string;
  name_de: string;
  name: string;
}

export interface Conference {
  id: number;
  name: string;
}

export type View = 'grid' | 'list';

export interface Sport {
  id: number;
  name: string;
}

export interface Formation {
  id: number;
  name: string;
}
