import styled from 'styled-components/macro';

export const Wrapper = styled.nav`
  position: fixed;
  top: 1rem;
  bottom: 1rem;
  left: 1rem;
  display: flex;
  padding: 0.25rem 0 0;
  margin-bottom: 1rem;
  background: var(--black);
  border-radius: 0.5rem 0.5rem 0 0;
  flex-direction: column;
  justify-content: space-between;

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1rem;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 1rem 2rem 0 2rem;
    border-color: var(--black) transparent transparent transparent;
  }
`;
