import * as React from 'react';
import { useEffect } from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { PageWrapper } from '../../../../components/PageWrapper';
import { Col, Row } from '../../../../components/Grid';
import { Heading } from '../../../../components/Heading';
import { useTranslation } from 'react-i18next';
import { Article } from '../../../../components/Article';
import { Overview } from './Overview';
import { Hr } from '../../../../components/Hr';
import { Button } from '../../../../components/Button';
import { selectUniversityForm } from './slice/selectors';
import { useUniversityFormSlice } from './slice';
import {
  fileSchema,
  numberSchema,
  numberSchemaOptional,
  optionSchema,
  stringSchema,
  stringSchemaOptional,
  urlSchemaOptional,
} from '../../../../../utils/validation-schemas';
import { Costs } from './Costs';
import { Academic } from './Academic';
import { University } from '../../../../../types/University';
import { InitialValues } from './slice/types';

const validationSchema = Yup.object().shape({
  name: stringSchema,
  city: stringSchema,
  state: stringSchema,
  country: optionSchema,
  total_enrollment: numberSchemaOptional,
  school_website: urlSchemaOptional,
  about: stringSchemaOptional,
  cost_tuition: numberSchema,
  cost_room: numberSchema,
  cost_other: numberSchema,
  cost_total: numberSchema,
  academics_other: stringSchemaOptional,
  academics_min_gpa: numberSchema,
  academics_toefl: numberSchema,
  academics_sat: numberSchema,
  academics_duolingo: numberSchema,
  logo: fileSchema,
});

interface Props {
  universityId?: number;
}

export function UniversityForm(props: Props) {
  const { universityId } = props;
  const { t } = useTranslation();
  const { initialValues, getLoading, deleteLoading } =
    useSelector(selectUniversityForm);
  const { actions } = useUniversityFormSlice();
  const dispatch = useDispatch();
  const history = useHistory();
  const { from } = queryString.parse(history.location.search);

  useEffect(() => {
    universityId
      ? dispatch(actions.getUniversity({ universityId }))
      : dispatch(actions.resetUniversity());
  }, [universityId]);

  const saveForm = (values: InitialValues): Promise<University> => {
    return new Promise((resolve, reject) =>
      dispatch(
        actions.saveUniversity({
          universityId,
          values,
          resolve,
          reject,
        }),
      ),
    );
  };

  const onSubmit = async values => {
    const university = await saveForm(values);
    history.push((from as string) || '/', { university });
  };

  const onDelete = async () => {
    try {
      await new Promise((resolve, reject) => {
        dispatch(
          actions.deleteUniversity({
            universityId,
            resolve,
            reject,
          }),
        );
      });

      history.push('/programs');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PageWrapper loading={getLoading}>
      <Row flexDirection="column">
        <Col>
          <Heading>{t('title.yourUniversity')}</Heading>
        </Col>
        <Col gutterY={2}>
          <Article>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, isSubmitting, errors }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Row gutterY={2}>
                      <Col xs={12} gutterY={2}>
                        <Overview />
                      </Col>
                      <Col xs={12} gutterY={2}>
                        <Hr />
                      </Col>
                      <Col xs={12} gutterY={2}>
                        <Costs />
                      </Col>
                      <Col xs={12} gutterY={2}>
                        <Hr />
                      </Col>
                      <Col xs={12} gutterY={2}>
                        <Academic />
                      </Col>
                      <Col xs={12} gutterY={2}>
                        <Hr />
                      </Col>
                      <Col xs={12} gutterY={2}>
                        <Row justifyContent="space-between">
                          <Col>
                            <Row>
                              <Col>
                                <Button border to={(from as string) || '/'}>
                                  {t('button.cancel')}
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  primary
                                  $loading={isSubmitting}
                                  type="submit"
                                >
                                  {universityId
                                    ? t('button.save')
                                    : t('button.add')}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            <Col>
                              {universityId ? (
                                <Button
                                  border
                                  invalid
                                  onClick={onDelete}
                                  $loading={deleteLoading}
                                >
                                  {t('button.delete')}
                                </Button>
                              ) : null}
                            </Col>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                );
              }}
            </Formik>
          </Article>
        </Col>
      </Row>
    </PageWrapper>
  );
}
