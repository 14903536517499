import React, { useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { AuthContext } from '../../context/AuthContext';
import { validateRoutePermissions } from '../../utils/validate-user-permissions';
import { PermissionType, RoleTagType } from '../../types/Dict';

interface IPrivateRouteProps extends RouteProps {
  permissions?: PermissionType[];
  tags?: RoleTagType[];
}

export const PrivateRoute = ({
  permissions,
  tags,
  ...rest
}: IPrivateRouteProps) => {
  const { isAuthenticated, user, loadingUserData } = useContext(AuthContext);
  const { hasAllPermissions, hasAllTags } = validateRoutePermissions({
    user,
    permissions,
    tags,
  });

  if (loadingUserData) {
    return null;
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{ pathname: '/auth/login', state: { from: rest.location } }}
      />
    );
  }

  if (!hasAllPermissions || !hasAllTags) {
    return <Redirect to={{ pathname: '/', state: { from: rest.location } }} />;
  }

  return <Route {...rest} />;
};
