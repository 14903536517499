import * as React from 'react';
import { useEffect } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import * as Yup from 'yup';

import { PageWrapper } from '../../../../components/PageWrapper';
import { Col, Row } from '../../../../components/Grid';
import { Heading } from '../../../../components/Heading';
import { useTranslation } from 'react-i18next';
import { Article } from '../../../../components/Article';
import { Overview } from './Overview';
import { Hr } from '../../../../components/Hr';
import { Button } from '../../../../components/Button';
import { Media } from './Media';
import { useDispatch, useSelector } from 'react-redux';
import { selectProgramForm } from './slice/selectors';
import { useProgramFormSlice } from './slice';
import {
  optionSchema,
  stringSchema,
  stringSchemaOptional,
  urlSchemaOptional,
} from '../../../../../utils/validation-schemas';
import { InitialValues } from './slice/types';
import { Program } from '../../../../../types/Program';

interface Props {
  programId?: number;
}

const validationSchema = Yup.object().shape({
  overview: Yup.object().shape({
    name: stringSchema,
    sport: optionSchema,
    website: urlSchemaOptional,
    division: optionSchema,
    university: optionSchema,
    conference: optionSchema,
    awards: stringSchemaOptional,
    logo: Yup.array(Yup.object()).min(1),
  }),
});

export function ProgramForm(props: Props) {
  const { programId } = props;
  const { t } = useTranslation();
  const { initialValues, getLoading, deleteLoading } =
    useSelector(selectProgramForm);
  const { actions } = useProgramFormSlice();
  const dispatch = useDispatch();
  const history = useHistory();
  const { from } = queryString.parse(history.location.search);

  useEffect(() => {
    programId
      ? dispatch(actions.getProgram({ programId }))
      : dispatch(actions.resetProgram());
  }, [programId]);

  const saveForm = (values: InitialValues): Promise<Program> => {
    return new Promise((resolve, reject) =>
      dispatch(
        actions.saveProgram({
          programId,
          values,
          resolve,
          reject,
        }),
      ),
    );
  };

  const onSubmit = async values => {
    const program = await saveForm(values);
    history.push((from as string) || '/', { program });
  };

  const onDelete = async () => {
    try {
      await new Promise((resolve, reject) => {
        dispatch(
          actions.deleteProgram({
            programId,
            resolve,
            reject,
          }),
        );
      });

      history.push('/programs');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PageWrapper loading={getLoading} withNavigation>
      <Row flexDirection="column">
        <Col>
          <Heading>{t('title.yourProgram')}</Heading>
        </Col>
        <Col gutterY={2}>
          <Article>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({ handleSubmit, isSubmitting }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Row gutterY={2}>
                      <Col xs={12} gutterY={2}>
                        <Overview />
                      </Col>
                      <Col xs={12} gutterY={2}>
                        <Hr />
                      </Col>
                      <Col xs={12} gutterY={2}>
                        <Media />
                      </Col>
                      <Col xs={12} gutterY={2}>
                        <Hr />
                      </Col>
                      <Col xs={12} gutterY={2}>
                        <Row justifyContent="space-between">
                          <Col>
                            <Row>
                              <Col>
                                <Button border to={(from as string) || '/'}>
                                  {t('button.cancel')}
                                </Button>
                              </Col>
                              <Col>
                                <Button
                                  primary
                                  $loading={isSubmitting}
                                  type="submit"
                                >
                                  {programId
                                    ? t('button.save')
                                    : t('button.add')}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            {programId ? (
                              <Button
                                border
                                invalid
                                onClick={onDelete}
                                $loading={deleteLoading}
                              >
                                {t('button.delete')}
                              </Button>
                            ) : null}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </form>
                );
              }}
            </Formik>
          </Article>
        </Col>
      </Row>
    </PageWrapper>
  );
}
