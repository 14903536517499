import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../../routes/PrivateRoute';

import { NewProgramPage } from './NewProgramPage/Loadable';
import { ProgramProfilePage } from './ProgramProfilePage/Loadable';
import { EditProgramPage } from './EditProgramPage';

export function Program() {
  return (
    <Switch>
      <PrivateRoute path="/program/create" component={NewProgramPage} exact />
      <PrivateRoute
        path="/program/:programId/edit"
        component={EditProgramPage}
        exact
      />
      <PrivateRoute
        path="/program/:programId/profile"
        component={ProgramProfilePage}
        exact
      />
    </Switch>
  );
}
