import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { PlayerProfile } from '../../../types/Player';
import { Icon } from '../Icon';
import { useWatchlistSlice } from '../../pages/WatchlistPage/slice';
import { selectPlayerIds } from '../../pages/WatchlistPage/slice/selectors';

interface Props {
  player: PlayerProfile | null;
  children: any;
  hasLink?: boolean;
  hasBookmark?: boolean;
  style?: React.CSSProperties;
}

export function PlayerCardWrapper(props: Props) {
  const { player, children, hasLink, hasBookmark, style } = props;
  const dispatch = useDispatch();
  const { actions } = useWatchlistSlice();
  const watchlistPlayerIds = useSelector(selectPlayerIds);

  const { id } = player || {};
  const inWatchlist = watchlistPlayerIds.indexOf(id as number) > -1;

  const onBookmarkClick = () => {
    if (!id) return;

    inWatchlist
      ? dispatch(actions.deleteFromWatchlist({ player_id: id }))
      : dispatch(actions.addToWatchlist({ player_id: id }));
  };

  return (
    <Wrapper active={inWatchlist} style={style}>
      <PlayerLink
        to={`/player/${id}/profile`}
        style={{ pointerEvents: hasLink ? 'auto' : 'none' }}
      >
        {children}
      </PlayerLink>
      {hasBookmark ? (
        <BookmarkButton onClick={onBookmarkClick}>
          <Icon name="bookmark-thin" />
        </BookmarkButton>
      ) : null}
    </Wrapper>
  );
}

PlayerCardWrapper.defaultProps = {
  hasLink: true,
  hasBookmark: true,
};

const PlayerLink = styled(Link)`
  display: block;
  position: relative;
  background: var(--grey-700);
  padding: 1rem 1rem 0;
  text-align: center;
  border-radius: 0.5rem 0.5rem 0 0;
  border: 1px solid var(--grey-600);
  height: 100%;

  &:before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2rem;
    margin-bottom: -1px;
    height: 2rem;
    background: var(--grey-600);
    content: '';
    display: block;
    clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
  }

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -2rem;
    height: 2rem;
    background: var(--grey-700);
    content: '';
    display: block;
    clip-path: polygon(50% 100%, 0% 0%, 100% 0%);
  }

  /*&:before,
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
  }

  &:before {
    bottom: calc(-2rem - 1px);
    border-width: 2rem 94px 0 94px;
    border-color: var(--grey-600) transparent transparent transparent;
  }

  &:after {
    bottom: -2rem;
    border-width: 2rem 94px 0 94px;
    border-color: var(--grey-700) transparent transparent transparent;
  }*/

  &:hover {
    border-color: var(--gold-30) !important;
    transition: border-color 0.2s;

    &:before {
      background-color: var(--gold-30) !important;
      transition: background-color 0.2s;
    }
  }
`;

const BookmarkButton = styled.button`
  position: absolute;
  top: 1px;
  right: 0.75rem;
  padding: 0 0.75rem 0.75rem;
  color: var(--grey-300);
  border: none;
  cursor: pointer;

  &:hover {
    color: var(--gold-30);
  }
`;

const Wrapper = styled.div<{ active?: boolean }>`
  position: relative;
  height: 100%;
  //width: 188px;
  //margin-bottom: 2rem;
  padding-bottom: 2rem;

  ${ifProp(
    'active',
    css`
      ${PlayerLink} {
        border-color: var(--gold-50);

        &:before {
          background-color: var(--gold-50);
        }
      }

      ${BookmarkButton} {
        color: var(--gold-30);
      }
    `,
  )}
`;
