import * as React from 'react';
import CellMeasurer from 'react-virtualized/dist/commonjs/CellMeasurer';
import CellMeasurerCache from 'react-virtualized/dist/commonjs/CellMeasurer/CellMeasurerCache';
import InfiniteLoader from 'react-virtualized/dist/commonjs/InfiniteLoader';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import styled from 'styled-components/macro';

import { Col, Row } from '../Grid';
import { PlayerProfile } from '../../../types/Player';
import { chunk } from '../../../utils/chunk';
import { PlayerCard } from '../PlayerCard';

interface Props {
  players: PlayerProfile[];
  getPlayers: ({
    take,
    skip,
    promiseResolver,
  }: {
    take: number;
    skip: number;
    promiseResolver: () => {};
  }) => void;
  total: number;
}

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 252 + 64,
});

export function PlayersGrid(props: Props) {
  const { players, getPlayers, total } = props;

  const colCount = 5;
  const playersByRow = chunk(players, colCount);
  const rowCount = Math.ceil(total / colCount);

  React.useEffect(() => {
    cache.clearAll();
  }, [total]);

  const isRowLoaded = ({ index }) => playersByRow[index];

  const loadMoreRows = ({ startIndex, stopIndex }) => {
    const increment = stopIndex - startIndex + 1;

    let promiseResolver;

    getPlayers({
      take: increment * colCount,
      skip: startIndex * colCount,
      promiseResolver,
    });

    return new Promise(resolve => {
      promiseResolver = resolve;
    });
  };

  const rowRenderer = ({ index, key, style, parent }) => {
    const rowPlayers = playersByRow[index];

    if (!rowPlayers) return null;

    return (
      <CellMeasurer cache={cache} key={key} parent={parent} rowIndex={index}>
        <div style={style}>
          <RowWrapper>
            <Row gutter={2}>
              {rowPlayers.map(player => (
                <Col xs={12 / 5} gutter={2} key={player.id}>
                  <PlayerCard player={player} />
                </Col>
              ))}
            </Row>
          </RowWrapper>
        </div>
      </CellMeasurer>
    );
  };

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={rowCount}
      threshold={8}
    >
      {({ onRowsRendered, registerChild }) => (
        <WindowScroller>
          {({ height, scrollTop }) => (
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  ref={registerChild}
                  className="List"
                  autoHeight
                  height={height}
                  width={width}
                  onRowsRendered={onRowsRendered}
                  rowCount={rowCount}
                  rowHeight={cache.rowHeight}
                  rowRenderer={rowRenderer}
                  scrollTop={scrollTop}
                />
              )}
            </AutoSizer>
          )}
        </WindowScroller>
      )}
    </InfiniteLoader>
  );
}

const RowWrapper = styled.div`
  padding-bottom: 2rem;
`;
