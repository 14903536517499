import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';

import { playerToDoSaga } from './saga';
import { GetAction, PlayerToDoState } from './types';
import { PlayerProfile, PlayerValidation } from '../../../../types/Player';

export const initialState: PlayerToDoState = {
  progress: {
    player_academics: 0,
    player_academics_school: 0,
    player_academics_university: 0,
    player_athletics: 0,
    player_career: 0,
    player_english_test: 0,
    player_intent_letter: 0,
    player_ncaa: 0,
    player_photos: 0,
    player_position: 0,
    player_preferences: 0,
    player_profile: 0,
    player_sat: 0,
    player_socials: 0,
    player_transcripts: 0,
    player_videos: 0,
    player_visum: 0,
    total: 0,
  },
  progressLoading: false,
  player: null,
  playerLoading: false,
};

const slice = createSlice({
  name: 'playerToDo',
  initialState,
  reducers: {
    getAllProgress(state, action: PayloadAction<GetAction>) {
      state.progressLoading = true;
    },
    getAllSuccess(state, action: PayloadAction<PlayerValidation>) {
      state.progressLoading = false;
      state.progress = action.payload;
    },
    getAllError(state) {
      state.progressLoading = false;
    },
    getPlayerUser(state, action: PayloadAction<GetAction>) {
      state.playerLoading = true;
    },
    getPlayerUserSuccess(state, action: PayloadAction<PlayerProfile>) {
      state.playerLoading = false;
      state.player = action.payload;
    },
    getPlayerUserError(state) {
      state.playerLoading = false;
    },
  },
});

export const { actions: playerToDoActions } = slice;

export const usePlayerToDoSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: playerToDoSaga });
  return { actions: slice.actions };
};
