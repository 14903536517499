import { all, call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError } from 'axios';

import { dictActions as actions } from '.';
import { api } from '../../services/api';

function* dataRequest(route) {
  try {
    return yield call(api.get, route, {
      params: { take: 1000, skip: 0 },
    });
  } catch (err) {
    return err as AxiosError;
  }
}

function* getDict() {
  const result = yield all({
    positions: dataRequest('/positions'),
    seasons: dataRequest('/seasons'),
    skills: dataRequest('/skills'),
    socials: dataRequest('/socials'),
    preferences: dataRequest('/preferences'),
    educationDegrees: dataRequest('/education-degree'),
    languageTestTypes: dataRequest('/language-test'),
    semesters: dataRequest('/semester'),
    divisions: dataRequest('/division'),
    admissions: dataRequest('/admission'),
    genders: dataRequest('/gender'),
    bodyParts: dataRequest('/bodyparts'),
    priorities: dataRequest('/priorities'),
    playerSortingFields: dataRequest('/player/sort-options'),
    programSortingFields: dataRequest('/program/sort'),
    searchRequestSortingFields: dataRequest('/search-request/sort-options'),
    coachTypes: dataRequest('/coachtype'),
    conferences: dataRequest('/conference'),
    programs: dataRequest('/program'),
    sports: dataRequest('/sport'),
    formations: dataRequest('/formation'),
    roles: dataRequest('/roles'),
  });

  yield put(actions.fillDict(result));
}

export function* dictSaga() {
  yield takeLatest(actions.getDict.type, getDict);
}
