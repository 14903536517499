import * as React from 'react';

import { UniversityForm } from '../forms/University';
import { useParams } from 'react-router-dom';
import { Navigation } from '../../../components/Navigation';

export function EditUniversityPage() {
  const params = useParams<{ universityId: string }>();
  const universityId = +params.universityId;

  return (
    <>
      <Navigation />
      <UniversityForm universityId={universityId} />
    </>
  );
}
