import { EventEmitter } from 'events';

type Uploads = string[];

const Constants = {
  CHANGE: 'change',
};

class Manager extends EventEmitter {
  private uploads: Uploads = [];

  constructor() {
    super();

    this.uploads = [];
  }

  private emitChange() {
    this.emit(Constants.CHANGE, this.uploads);
  }

  addChangeListener(callback) {
    this.addListener(Constants.CHANGE, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(Constants.CHANGE, callback);
  }

  addUpload(id: string) {
    this.uploads.push(id);
    this.emitChange();
  }

  removeUpload(id: string) {
    this.uploads = this.uploads.filter(upload => upload !== id);
    this.emitChange();
  }
}

export const UploadsManager = new Manager();
