import React from 'react';
import styled from 'styled-components';
import OriginAsyncSelect from 'react-select/async';

import { selectStyles } from './styles';
import { DropdownIndicator } from './components';
import { useTranslation } from 'react-i18next';

export const AsyncSelect = props => {
  const { t } = useTranslation();

  const {
    options = [],
    value,
    onChange,
    name,
    loadOptions,
    isLoading,
    components,
    placeholder,
    invalid,
  } = props;

  return (
    <StyledAsyncSelect
      classNamePrefix="react-select"
      options={options}
      value={value}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator: DropdownIndicator,
        ...components,
      }}
      onChange={onChange}
      loadOptions={loadOptions}
      isLoading={isLoading}
      placeholder={placeholder}
      noOptionsMessage={({ inputValue }) => {
        return inputValue
          ? t('placeholder.noOptions')
          : t('placeholder.typeToSearch');
      }}
      isSearchable
      invalid={invalid}
    />
  );
};

const StyledAsyncSelect = styled(OriginAsyncSelect)<{
  border?: boolean;
  invalid?: boolean;
}>`
  ${selectStyles}
`;
