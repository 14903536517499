import React from 'react';
import styled from 'styled-components/macro';

export const Label = styled.label`
  display: block;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  color: var(--grey-300);
  letter-spacing: 0.8px;
  cursor: inherit;
`;
