import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { coachProfileFormSaga } from './saga';
import {
  CoachProfileFormState,
  GetAction,
  ProfileForm,
  SaveAction,
  UserForm,
} from './types';

export const initialState: CoachProfileFormState = {
  initialValues: {
    profile: {
      photo_id: null,
      photo: null,
      coach_type: undefined,
      university: undefined,
      contact_email: '',
      contact_phone: '',
      sport: undefined,
      bio: '',
      program: undefined,
      previous_experience: '',
      alma_mater: '',
      formation: undefined,
      coaching_style: '',
    },
    user: {
      first_name: '',
      last_name: '',
    },
  },
  getProfileLoading: false,
  getUserLoading: false,
};

const slice = createSlice({
  name: 'coachProfileForm',
  initialState,
  reducers: {
    getProfile(state, action: PayloadAction<GetAction>) {
      state.getProfileLoading = true;
    },
    getProfileSuccess(state, action: PayloadAction<ProfileForm>) {
      state.initialValues.profile = action.payload;
      state.getProfileLoading = false;
    },
    getProfileError(state, action: PayloadAction<any>) {
      state.getProfileLoading = false;
    },
    getUser(state, action: PayloadAction<GetAction>) {
      state.getUserLoading = true;
    },
    getUserSuccess(state, action: PayloadAction<UserForm>) {
      state.initialValues.user = action.payload;
      state.getUserLoading = false;
    },
    getUserError(state, action: PayloadAction<any>) {
      state.getUserLoading = false;
    },

    saveProfile(state, action: PayloadAction<SaveAction>) {},
    saveSuccess(state, action: PayloadAction<any>) {},
    saveError(state, action: PayloadAction<any>) {},

    sendForReview(state, action: PayloadAction<GetAction>) {},
  },
});

export const { actions: coachProfileFormActions } = slice;

export const useCoachProfileSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: coachProfileFormSaga });
  return { actions: slice.actions };
};
