import * as React from 'react';

import { Profile } from '../forms/Profile';
import { Navigation } from '../../../components/Navigation';

export function CoachEditPage() {
  return (
    <>
      <Navigation />
      <Profile />
    </>
  );
}
