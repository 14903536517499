import styled from 'styled-components/macro';
import { ifProp } from 'styled-tools';
import { css } from 'styled-components';

interface Props {
  level?: 1 | 2;
}

export const P = styled.p<Props>`
  margin: 0;
  white-space: break-spaces;

  & + & {
    margin-top: 1rem;
  }

  ${ifProp(
    { level: 2 },
    css`
      font-size: 14px;
      line-height: 18px;
    `,
  )}
`;

P.defaultProps = {
  level: 1,
};
