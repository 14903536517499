import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Heading } from '../../../../../components/Heading';
import { Col, Row } from '../../../../../components/Grid';
import { NumberField, TextAreaField } from '../../../../../components/Fields';

export function Academic(props) {
  const { t } = useTranslation();

  return (
    <Row gutter={2}>
      <Col xs={12} gutter={2}>
        <Heading level={2}>{t('title.academic')}</Heading>
      </Col>
      <Col xs={6} gutterY={2} gutter={2}>
        <Row gutter={2} gutterY={2}>
          <Col xs={6} gutter={2} gutterY={2}>
            <NumberField
              label={t('label.minimumGPA')}
              name="academics_min_gpa"
              thousandSeparator
            />
          </Col>
          <Col xs={6} gutter={2} gutterY={2}>
            <NumberField
              label={t('label.toeflScore')}
              name="academics_toefl"
              thousandSeparator
            />
          </Col>
          <Col xs={6} gutter={2} gutterY={2}>
            <NumberField
              label={t('label.satScore')}
              name="academics_sat"
              thousandSeparator
            />
          </Col>
          <Col xs={6} gutter={2} gutterY={2}>
            <NumberField
              label={t('label.duolingoScore')}
              name="academics_duolingo"
              thousandSeparator
            />
          </Col>
        </Row>
      </Col>
      <Col xs={6} gutterY={2} gutter={2}>
        <TextAreaField
          label={t('label.otherAdmissionRequirements')}
          name="academics_other"
          minRows={5}
          placeholder={t('placeholder.otherAdmissionRequirements')}
        />
      </Col>
    </Row>
  );
}
