import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { PrivateRoute } from '../../routes/PrivateRoute';

import { CoachSignUpPage } from './CoachSignUpPage/Loadable';
import { CoachHomePage } from './CoachHomePage/Loadable';
import { CoachProfilePage } from './CoachProfilePage/Loadable';
import { CoachOnBoardingPage } from './CoachOnBoardingPage';
import { CoachEditPage } from './CoachEditPage';
import { CoachPlayersPage } from './CoachPlayersPage';

export function Coach() {
  return (
    <Switch>
      <PrivateRoute path="/coach/onboarding" component={CoachOnBoardingPage} />
      <PrivateRoute path="/coach/edit" component={CoachEditPage} />
      <PrivateRoute path="/coach/players" component={CoachPlayersPage} />
      <Route path="/coach/sign-up" component={CoachSignUpPage} />
      <PrivateRoute
        path="/coach/:coachId/profile"
        component={CoachProfilePage}
        exact
      />
      <PrivateRoute path="/coach" component={CoachHomePage} exact />
    </Switch>
  );
}
