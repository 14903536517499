import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';
import { Option } from '../components/Select/types';
import { SkillType } from '../../types/Dict';
import { toOptions } from '../../utils/to-options';
import { sortBy } from '../../utils/sort-by';

const selectSlice = (state: RootState) => state?.dict || initialState;
export const selectLoaded = createSelector(
  [selectSlice],
  state => state.loaded,
);

export const selectPositions = createSelector(
  [selectSlice],
  state => state.positions,
);

export const selectPositionOptions = createSelector(
  [selectPositions],
  (positions): Option[] => toOptions(positions, ['id', 'description']),
);

export const selectSeasons = createSelector(
  [selectSlice],
  state => state.seasons,
);

export const selectSeasonOptions = createSelector(
  [selectSeasons],
  (seasons): Option[] => toOptions(seasons, ['id', 'name']),
);

export const selectSkills = createSelector(
  [selectSlice, (state, props?: { type: SkillType }) => props?.type],
  (state, type) => {
    if (!type) return state.skills;

    return state.skills.filter(skill => skill.type === type);
  },
);

export const selectSocials = createSelector(
  [selectSlice],
  state => state.socials,
);

export const selectPreferences = createSelector([selectSlice], state =>
  [...state.preferences].sort(sortBy('order')),
);

export const selectPreferenceOptions = createSelector(
  [selectPreferences],
  preferences => toOptions(preferences, ['id', 'name_en']),
);

export const selectEducationDegrees = createSelector(
  [selectSlice],
  state => state.educationDegrees,
);

export const selectEducationDegreeOptions = createSelector(
  [selectEducationDegrees],
  degrees => toOptions(degrees, ['id', 'name_en']),
);

export const selectSchoolDegreeOptions = createSelector(
  [selectEducationDegrees],
  degrees =>
    toOptions(
      degrees.filter(({ type }) => type === 'school'),
      ['id', 'name_en'],
    ),
);

export const selectUniversityDegreeOptions = createSelector(
  [selectEducationDegrees],
  degrees =>
    toOptions(
      degrees.filter(({ type }) => type === 'university'),
      ['id', 'name_en'],
    ),
);

export const selectLanguageTestTypes = createSelector(
  [selectSlice],
  state => state.languageTestTypes,
);

export const selectLanguageTestTypeOptions = createSelector(
  [selectLanguageTestTypes],
  languageTestTypes => toOptions(languageTestTypes, ['id', 'name_en']),
);

export const selectSemesters = createSelector(
  [selectSlice],
  state => state.semesters,
);

export const selectSemesterOptions = createSelector(
  [selectSemesters],
  semesters => toOptions(semesters, ['id', 'name_en']),
);

export const selectDivisions = createSelector(
  [selectSlice],
  state => state.divisions,
);

export const selectDivisionOptions = createSelector(
  [selectDivisions],
  divisions => toOptions(divisions, ['id', 'name']),
);

export const selectAdmissions = createSelector(
  [selectSlice],
  state => state.admissions,
);

export const selectAdmissionOptions = createSelector(
  [selectAdmissions],
  admissions => toOptions(admissions, ['id', 'name_en']),
);

export const selectGenders = createSelector(
  [selectSlice],
  state => state.genders,
);

export const selectGenderOptions = createSelector([selectGenders], genders =>
  toOptions(genders, ['id', 'name_en']),
);

export const selectBodyParts = createSelector(
  [selectSlice],
  state => state.bodyParts,
);

export const selectBodyPartOptions = createSelector(
  [selectBodyParts],
  bodyParts => toOptions(bodyParts, ['id', 'name_en']),
);

export const selectPlayerSortingFields = createSelector(
  [selectSlice],
  state => state.playerSortingFields,
);

export const selectPriorities = createSelector(
  [selectSlice],
  state => state.priorities,
);

export const selectPriorityOptions = createSelector(
  [selectPriorities],
  priorities => toOptions(priorities, ['id', 'name_en']),
);

export const selectSearchRequestSortingFields = createSelector(
  [selectSlice],
  state => state.searchRequestSortingFields,
);

export const selectCoachTypes = createSelector(
  [selectSlice],
  state => state.coachTypes,
);

export const selectCoachTypeOptions = createSelector(
  [selectCoachTypes],
  coachTypes => toOptions(coachTypes, ['id', 'name_en']),
);

export const selectConferences = createSelector(
  [selectSlice],
  state => state.conferences,
);

export const selectConferenceOptions = createSelector(
  [selectConferences],
  conferences => toOptions(conferences, ['id', 'name']),
);

export const selectPrograms = createSelector(
  [selectSlice],
  state => state.programs,
);

export const selectProgramOptions = createSelector([selectPrograms], programs =>
  toOptions(programs, ['id', 'name']),
);

export const selectSports = createSelector(
  [selectSlice],
  state => state.sports,
);

export const selectSportOptions = createSelector([selectSports], sports =>
  toOptions(sports, ['id', 'name']),
);

export const selectFormations = createSelector(
  [selectSlice],
  state => state.formations,
);

export const selectFormationOptions = createSelector(
  [selectFormations],
  formations => toOptions(formations, ['id', 'name']),
);

export const selectRoles = createSelector([selectSlice], state => state.roles);

export const selectRoleOptions = createSelector([selectRoles], roles =>
  toOptions(roles, ['id', 'name']),
);
