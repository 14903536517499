import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';

import { Heading } from '../../../../../components/Heading';
import { Col, Row } from '../../../../../components/Grid';
import {
  AsyncSelectField,
  FileField,
  SelectField,
  TextAreaField,
  TextField,
} from '../../../../../components/Fields';
import {
  selectCoachTypeOptions,
  selectSportOptions,
} from '../../../../../slice/selectors';
import { Button } from '../../../../../components/Button';
import { Label } from '../../../../../components/Label';

export function Personal(props) {
  const { saveProfile } = props;
  const { t } = useTranslation();
  const coachTypeOptions = useSelector(selectCoachTypeOptions);
  const sportOptions = useSelector(selectSportOptions);
  const [addUniversityLoading, setAddUniversityLoading] =
    useState<boolean>(false);
  const [addProgramLoading, setAddProgramLoading] = useState<boolean>(false);
  const { values } = useFormikContext<{ [name: string]: any }>();
  const history = useHistory();

  const addUniversity = async () => {
    setAddUniversityLoading(true);
    await saveProfile(values);
    setAddUniversityLoading(false);
    history.push(`/university/create?from=${history.location.pathname}`);
  };

  const addProgram = async () => {
    setAddProgramLoading(true);
    await saveProfile(values);
    setAddProgramLoading(false);
    history.push(`/program/create?from=${history.location.pathname}`);
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Heading level={2}>{t('title.personal')}</Heading>
        </Col>
        <Col xs={12} gutterY={2}>
          <FileField
            label={t('button.uploadProfilePicture')}
            name="profile.photo"
            border
          />
        </Col>
        <Col xs={12}>
          <Row gutter={2}>
            <Col xs={8} gutter={2} gutterY={2}>
              <Row gutter={2} gutterY={2}>
                <Col xs={6} gutter={2} gutterY={2}>
                  <TextField
                    label={t('label.firstName')}
                    name="user.first_name"
                  />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <SelectField
                    label={t('label.position')}
                    options={coachTypeOptions}
                    name="profile.coach_type"
                  />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <TextField
                    label={t('label.lastName')}
                    name="user.last_name"
                  />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <AsyncSelectField
                    label={t('label.university')}
                    name="profile.university"
                    source="/university"
                    listKey="universities"
                    optionKeys={['id', 'name']}
                  />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <Row>
                    <Col xs={12}>
                      <Heading level={3}>{t('title.agencyViewOnly')}</Heading>
                    </Col>
                    <Col xs={12} gutterY={0.5}>
                      <Label>
                        {t('infoMessage.forms.coachAgencyViewOnly')}
                      </Label>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <Row>
                    <Col xs={12}>
                      <Label>{t('label.cantFindYourUniversity')}</Label>
                    </Col>
                    <Col xs={12} gutterY={0.5}>
                      <Button
                        border
                        onClick={addUniversity}
                        $loading={addUniversityLoading}
                      >
                        {t('button.addUniversity')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <TextField
                    name="profile.contact_email"
                    label={t('label.email')}
                  />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <SelectField
                    label={t('label.sports')}
                    name="profile.sport"
                    options={sportOptions}
                  />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <TextField
                    name="profile.contact_phone"
                    label={t('label.phoneNumber')}
                  />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <AsyncSelectField
                    label={t('label.nameOfAthleticProgram')}
                    name="profile.program"
                    source="/program"
                    listKey="programs"
                    optionKeys={['id', 'name']}
                  />
                </Col>
                <Col xs={6} gutter={2} gutterY={2}></Col>
                <Col xs={6} gutter={2} gutterY={2}>
                  <Row>
                    <Col xs={12}>
                      <Label>{t('label.cantFindYourProgram')}</Label>
                    </Col>
                    <Col xs={12} gutterY={0.5}>
                      <Button
                        border
                        onClick={addProgram}
                        $loading={addProgramLoading}
                      >
                        {t('button.addProgram')}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={4} gutterY={2} gutter={2}>
              <TextAreaField
                label={t('label.bio')}
                name="profile.bio"
                minRows={10}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
