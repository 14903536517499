import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

import { watchlistActions as actions } from '.';
import { api } from '../../../../services/api';
import { AddRequest, GetWatchlistResponse } from './types';
import {
  selectFilterRequest,
  selectSortBy,
  selectSortDirection,
} from './selectors';
import { WatchlistPlayer } from '../../../../types/Watchlist';

function* getPlayers(action: PayloadAction<any>) {
  try {
    yield delay(500);
    const {
      take = 40,
      skip = 0,
      promiseResolver = () => {},
    } = action.payload || {};
    const filterRequest = yield select(selectFilterRequest);
    const sortBy = yield select(selectSortBy);
    const sortDirection = yield select(selectSortDirection);

    const response: AxiosResponse<GetWatchlistResponse> = yield call(
      api.get,
      `/watchlist/all`,
      {
        params: {
          take,
          skip,
          sort: sortBy.value,
          sort_direction: sortDirection,
          ...filterRequest,
        },
      },
    );
    if (skip === 0) yield put(actions.resetPlayers());
    yield put(actions.getSuccess(response));
    promiseResolver && promiseResolver();
  } catch (err) {
    const error = err as AxiosError;

    yield put(actions.getError(error));
  }
}

function* getIds() {
  try {
    const response: AxiosResponse<number[]> = yield call(
      api.get,
      `/watchlist/ids`,
    );

    yield put(actions.getIdsSuccess(response));
  } catch (err) {}
}

function* addToWatchlist(action: PayloadAction<AddRequest>) {
  try {
    const { player_id } = action.payload;
    const response: AxiosResponse<WatchlistPlayer> = yield call(
      api.post,
      `/watchlist`,
      {
        player_id,
      },
    );

    yield put(actions.addSuccess(response));
  } catch (err) {}
}

function* deleteFromWatchlist(action: PayloadAction<AddRequest>) {
  try {
    const { player_id } = action.payload;
    yield call(api.delete, `/watchlist/${player_id}`);

    yield put(actions.deleteSuccess(player_id));
  } catch (err) {}
}

export function* watchlistSaga() {
  yield takeLatest(actions.getPlayers.type, getPlayers);
  yield takeLatest(actions.changeFilter.type, getPlayers);
  yield takeLatest(actions.clearFilter.type, getPlayers);
  yield takeLatest(actions.resetFilter.type, getPlayers);
  yield takeLatest(actions.changeSort.type, getPlayers);
  yield takeLatest(actions.getIds.type, getIds);
  yield takeLatest(actions.addToWatchlist.type, addToWatchlist);
  yield takeLatest(actions.deleteFromWatchlist.type, deleteFromWatchlist);
}
