import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Heading } from '../../../../../components/Heading';
import { Col, Row } from '../../../../../components/Grid';
import { NumberField } from '../../../../../components/Fields';
import { P } from '../../../../../components/P';

export function Costs(props) {
  const { t } = useTranslation();

  return (
    <Row gutter={2}>
      <Col xs={12} gutter={2}>
        <Heading level={2}>{t('title.costs')}</Heading>
      </Col>
      <Col xs={6} gutterY={0.5} gutter={2}>
        <P>{t('infoMessage.forms.universityCosts')}</P>
      </Col>
      <Col xs={12} gutterY={2} gutter={2}>
        <Row gutter={2}>
          <Col xs={3} gutter={2}>
            <NumberField
              label={t('label.tuitionAndFees')}
              name="cost_tuition"
              prefix="$"
              thousandSeparator
            />
          </Col>
          <Col xs={3} gutter={2}>
            <NumberField
              label={t('label.roomAndBoard')}
              name="cost_room"
              prefix="$"
              thousandSeparator
            />
          </Col>
          <Col xs={3} gutter={2}>
            <NumberField
              label={t('label.otherCosts')}
              name="cost_other"
              prefix="$"
              thousandSeparator
            />
          </Col>
          <Col xs={3} gutter={2}>
            <NumberField
              label={t('label.totalCostOfAttendance')}
              name="cost_total"
              prefix="$"
              thousandSeparator
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
