import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { AxiosError } from 'axios';

import { dictSaga } from './saga';
import { DictState } from './types';

export const initialState: DictState = {
  loading: false,
  loaded: false,
  positions: [],
  seasons: [],
  skills: [],
  socials: [],
  preferences: [],
  educationDegrees: [],
  languageTestTypes: [],
  semesters: [],
  divisions: [],
  admissions: [],
  genders: [],
  bodyParts: [],
  priorities: [],
  playerSortingFields: {},
  searchRequestSortingFields: {},
  coachTypes: [],
  conferences: [],
  programs: [],
  sports: [],
  formations: [],
  roles: [],
};

const getDictValues = (action, key) => {
  return action.payload[key] instanceof AxiosError
    ? initialState[key]
    : action.payload[key].data;
};

const slice = createSlice({
  name: 'dict',
  initialState,
  reducers: {
    getDict(state) {
      state.loading = true;
    },
    fillDict(state, action: PayloadAction<any>) {
      state.loaded = false;

      state.positions = getDictValues(action, 'positions');
      state.seasons = getDictValues(action, 'seasons');
      state.skills = getDictValues(action, 'skills');
      state.socials = getDictValues(action, 'socials');
      state.preferences = getDictValues(action, 'preferences');
      state.educationDegrees = getDictValues(action, 'educationDegrees').data;
      state.languageTestTypes = getDictValues(action, 'languageTestTypes');
      state.semesters = getDictValues(action, 'semesters');
      state.divisions = getDictValues(action, 'divisions');
      state.admissions = getDictValues(action, 'admissions');
      state.genders = getDictValues(action, 'genders');
      state.bodyParts = getDictValues(action, 'bodyParts');
      state.playerSortingFields = getDictValues(action, 'playerSortingFields');
      state.priorities = getDictValues(action, 'priorities');
      state.searchRequestSortingFields = getDictValues(
        action,
        'searchRequestSortingFields',
      );
      state.coachTypes = getDictValues(action, 'coachTypes')?.coachtypes || [];
      state.conferences =
        getDictValues(action, 'conferences')?.conferences || [];
      state.programs = getDictValues(action, 'programs')?.programs || [];
      state.sports = getDictValues(action, 'sports')?.sports || [];
      state.formations = getDictValues(action, 'formations') || [];
      state.roles = getDictValues(action, 'roles') || [];
    },
  },
});

export const { actions: dictActions } = slice;

export const useDictSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: dictSaga });
  return { actions: slice.actions };
};
