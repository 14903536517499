import * as React from 'react';

import { Wrapper } from './Wrapper';
import { ItemButton, ItemLink } from './Item';
import { Icon } from '../Icon';
import { AuthContext } from '../../../context/AuthContext';
import { CircleImage } from '../CircleImage';
import { getFileLink } from '../../../utils/get-file-link';

interface Props {}

export function Navigation(props: Props) {
  const { signOut } = React.useContext(AuthContext);
  const { user, accessToken } = React.useContext(AuthContext);

  const AgencyNav = (
    <>
      <ItemLink to="/agency" exact>
        <Icon name="home" />
      </ItemLink>
      <ItemLink to="/agency/players" exact>
        <Icon name="person" />
      </ItemLink>
      <ItemLink to="/search-request" exact>
        <Icon name="binocular" />
      </ItemLink>
      <ItemLink to="/programs" exact>
        <Icon name="college" />
      </ItemLink>
      <ItemLink to="/coaches" exact>
        <Icon name="whistle" />
      </ItemLink>
      <ItemLink to="/watchlist" exact>
        <Icon name="bookmark" />
      </ItemLink>
    </>
  );

  const CoachNav = (
    <>
      <ItemLink to={`/coach`} exact>
        <Icon name="home" />
      </ItemLink>
      <ItemLink to="/coach/players" exact>
        <Icon name="person" />
      </ItemLink>
      <ItemLink to={`/search-request`} exact>
        <Icon name="binocular" />
      </ItemLink>
      <ItemLink to={`/watchlist`} exact>
        <Icon name="bookmark" />
      </ItemLink>
    </>
  );

  const CoachBottomNav = (
    <>
      <ItemLink to={`/coach/${user?.id}/profile`} exact>
        <CircleImage
          imageSrc={getFileLink(
            user?.coach_profile?.photo || null,
            accessToken,
          )}
          size={32}
        />
      </ItemLink>
      {user?.coach_profile?.program ? (
        <ItemLink
          to={`/program/${user?.coach_profile?.program?.id}/profile`}
          exact
        >
          <CircleImage
            imageSrc={getFileLink(
              user?.coach_profile?.program?.logo || null,
              accessToken,
            )}
            size={32}
          />
        </ItemLink>
      ) : null}
    </>
  );

  const PlayerNav = (
    <>
      <ItemLink to={`/player/${user?.id}`} exact>
        <Icon name="home" />
      </ItemLink>
      <ItemLink to={`/player/${user?.id}/profile`}>
        <Icon name="person" />
      </ItemLink>
      <ItemLink to="/programs" exact>
        <Icon name="college" />
      </ItemLink>
      <ItemLink to="/coaches" exact>
        <Icon name="whistle" />
      </ItemLink>
    </>
  );

  return (
    <Wrapper>
      <div>
        {['agency_member', 'super_admin', 'agency_owner'].indexOf(
          user?.role?.tag,
        ) !== -1
          ? AgencyNav
          : null}
        {['player'].indexOf(user?.role?.tag) !== -1 ? PlayerNav : null}
        {['coach'].indexOf(user?.role?.tag) !== -1 ? CoachNav : null}
      </div>
      <div>
        {['coach'].indexOf(user?.role?.tag) !== -1 ? CoachBottomNav : null}
        {/*<ItemLink to="/mind-game-sport">
          <Icon name="logo" />
        </ItemLink>*/}
        <ItemLink to="/settings">
          <Icon name="settings" />
        </ItemLink>
        <ItemButton onClick={signOut}>
          <Icon name="log-out" />
        </ItemButton>
      </div>
    </Wrapper>
  );
}
